// .camper-list-section {
//   padding: 41px 0 217px;

//   @include md-block {
//     margin: 25px 0 200px;
//   }
//   @include sm-block {
//     margin: 12px 0 100px;
//   }
// }
.camper-list {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: 1fr auto;
  column-gap: 1.5rem;

  @media (min-width: 480px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(var(--_columns, 3), minmax(0, 1fr));
  }

  @include sm-block {
    .App_search-page & {
      grid-template-columns: minmax(0, 1fr);
      gap: 2rem;
    }
  }
}
