.partners {
  position: relative;
  display: flex;
  align-items: center;
  padding: 49px 0;
  margin-top: 57px;

  @include md-block {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 0;
  }

  &::after,
  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 1px;
    background-color: $white;
    left: 0;
  }

  &::after {
    bottom: 0;
  }

  &::before {
    top: 0;
  }
}
.partners__title {
  margin: 0 152px 0 0;

  @include md-block {
    margin: 0 0 16px 0;
  }
}
.partners__list {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fill, minmax(10.375rem, 1fr));
  width: 100%;
  max-width: 61.75rem;
  gap: 20px 24px;

  @include sm-block {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-column-gap: 12px;
  }
}
