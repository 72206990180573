@import './components';

.rent-page-info-section {
    padding: 50px 0 65px;

    @include md-block{
        padding: 30px 0;
    };
    @include sm-block{
        padding: 20px 0;
    };
}
.rent-page-info {
    @include row-flex();
}
.rent-page-info__col {
    @include col();
    @include size(6);
    @include size-lg(6);
    @include size-md(12);
}
.rent-page-info__title {
    @include fp(32, 40);
    margin-bottom: 16px;
    font-family: 'Anton';
    font-weight: 100;
}
.rent-page-info__description {
    @include fp(16, 20);
    margin-bottom: 24px;
    color: rgba($primary, .8);
}
.rent-page-info__seller {
    padding-left: 16px;
    position: relative;
    margin-bottom: 132px;

    @include md-block{
        margin-bottom: 64px;
    };

    &::after {
        position: absolute;
        content: '';
        height: 100%;
        width: 1px;
        background-color: $red;
        left: 0;
        top: 0;
    }
}
.rent-page-info__seller-name {
    @include fp(32, 40);
    font-family: 'Anton';
    font-weight: 100;
    margin-bottom: 12px;
}
.rent-page-info__seller-info {

}
.rent-page-info__seller-info-point {
    display: flex;

    &:not(:last-child) {
        margin-bottom: 12px;
    }
}
.rent-page-info__seller-info-label {
    font-family: 'RobotoC';
    @include fp(20, 24);
    max-width: 83px;
    width: 100%;
    margin-right: 10px;
}
.rent-page-info__seller-info-link {
    @include fp(20, 24);
}
.rent-page-info__slider-wrapper {
    position: relative;
    padding-bottom: 96px;
    max-width: 489px;
    width: 100%;
    margin: 0 auto;

    @include md-block{
        margin-bottom: 64px;
    };
    @include sm-block{
        margin-bottom: 0;
        padding-bottom: 56px;
    };
}
.rent-page-info__image {
    @include col();
    @include size(6);
    @include size-lg(6);
    @include size-md(12);
    height: auto;
    border-radius: 8px;
    overflow: hidden;

    @include md-block{
        height: 400px
    };
    @include sm-block{
        display: none;
    };
}
