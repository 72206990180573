@import "./components";

.filter-options {
  display: flex;
  flex-direction: column;
  padding: 0 40px 80px;
}
.filter-options__option {
  text-transform: uppercase;
  position: relative;

  &:not(:last-child) {
    padding-bottom: 12px;

    &::after {
      position: absolute;
      content: "";
      width: 100%;
      height: 1px;
      background-color: $primary;
      bottom: 0;
      left: 0;
    }
  }

  &:not(:first-child) {
    margin-top: 24px;
  }
}

.filter-options__option-title {
  @include fp(16, 24);
  font-family: $font-roboto;
}
.filter-options__option-params {
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;
}
.filter-options__option-param {
  @include fp(12, 24);
  @include anim();
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  border: 1px solid $primary;
  cursor: pointer;
  margin-bottom: 12px;

  &:not(:last-child) {
    margin-right: 8px;
  }
  &:hover {
    color: $white;
    background-color: $primary;
  }

  &.active {
    color: $white;
    background-color: $primary;
  }
}
.filter-options__option-oval {
  .filter-options__option-param {
    border-radius: 40px;
  }
}
.filter-options__option-square {
  .filter-options__option-param {
    border-radius: 4px;
  }
}
