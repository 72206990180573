@import "./components";

.camper-detailed-information-section {
  padding: 48px 0 96px;

  @include md-block {
    padding: 48px 0 80px;
  }
  @include sm-block {
    padding: 32px 0 48px;
  }
}
.camper-detailed-information {
  @include row-flex();
}

.camper-detailed-information__col {
  @include col();
  @include size(6);
  @include size-lg(6);
  @include size-md(12);

  display: flex;
  flex-direction: column;

  & + .camper-detailed-information__col {
    @include md-block {
      margin-top: 48px;
    }
    @include sm-block {
      margin-top: 16px;
    }
  }
}
.camper-detailed-information__breadcrumbs {
}
.camper-detailed-information__camper-slider {
  margin-top: 40px;

  @include sm-block {
    margin-top: 32px;
  }
}
.camper-detailed-information__feature-box-scales {
  margin-left: auto;
}
.camper-detailed-information__header {
  margin-top: 54px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 24px;
  flex-wrap: wrap;
  gap: 0.5rem;

  @include lg-block {
    margin-top: 64px;
  }
  @include md-block {
    margin-top: 25px;
  }
  @include sm-block {
    display: block;
  }
}

.camper-detailed-information__product-price {
  @media (min-width: 36rem) {
    align-items: flex-end;
    margin-left: auto;
  }

  @include sm-block {
    margin-top: 1rem;
  }
}

.camper-detailed-information__row {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  position: relative;
  margin-bottom: 20px;

  &::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 1px;
    background-color: $primary;
    bottom: 0;
    right: 0;
  }
}
.camper-point__feature-box {
  z-index: 10;
}
.camper-detailed-information__leasing {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: auto;
}
.camper-detailed-information__leasing-label {
  @include fp(14, 14);
  margin-bottom: 6px;
}
.camper-detailed-information__leasing-price {
  font-family: $font-roboto, sans-serif;
  @include fp(16, 16);
}
.camper-detailed-information__insurances {
  display: flex;
  margin-left: auto;

  & li + li {
    margin-left: 17px;
  }

  a {
    font-family: $font-roboto, sans-serif;
    text-decoration: underline;
  }
}
.camper-detailed-information__specifications {
  margin-bottom: 32px;
}
.camper-detailed-information__specifications-row {
  display: flex;
  justify-content: space-between;
  @include fp(18, 20);
  position: relative;

  &:not(:last-child) {
    padding-bottom: 10px;

    &::after {
      position: absolute;
      content: "";
      width: 100%;
      height: 1px;
      background-color: $primary;
      bottom: 0;
      right: 0;
    }
  }
  &:not(:first-child) {
    padding-top: 10px;
  }

  &.no-line {
    &::after {
      display: none;
    }
  }
}
.camper-detailed-information__specifications-label {
  font-family: $font-roboto;
}
.camper-detailed-information__specifications-data {
  font-family: $font-roboto;
  margin-left: auto;

  &:first-letter {
    text-transform: uppercase;
  }
}
