.feedback-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  & > :not(:last-child) {
      margin-bottom: 32px;
  }

  textarea {
      height: 160px;
  }

  &__success {
    padding-block: 5rem;

    @media (min-width: 64rem) {
      padding-block: 7rem 0;
    }

    & p {
      text-align: center;
      font-size: 1.25rem;
      font-weight: 500;
      line-height: 1.2;
    }
  }
}
