$burgerBar: 2px;
$burgerBarOpen: 8px;
$burgerBarColor: $black;

.Burger {
    display: none;
    margin-left: 24px;
    border: none;
    background-color: transparent;

    @include md-block {
        display: block;
    }
}
.Burger-menu {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    height: 18px;
    width: 27px;
    cursor: pointer;
    margin: 0 auto;
    transition: 0.3s all ease-out;
    background-color: transparent;

    @include xs-block {
        margin: 0 0 0 auto;
    }

    .menuMobActive & {
        background-color: transparent;
    }
}
.Burger-menu__bar {
    height: $burgerBar;
    background-color: $black;
    width: 100%;
    transition: 0.3s all ease-out;
    margin: 0 auto;

    .menuMobActive & {
        background-color: $black;
    }
}
.js-burger-menu-active {
}
.menuMobActive .Burger-menu__bar:first-child {
    transform: translate(0, $burgerBarOpen);
}
.menuMobActive .Burger-menu__bar:nth-child(2) {
    width: 0;
}
.menuMobActive .Burger-menu__bar:last-child {
    transform: translate(0, -$burgerBarOpen);
}

.menuMobActive .Burger-menu__bar:first-child {
    transform: translate(0, $burgerBarOpen) rotate(45deg);
}
.menuMobActive .Burger-menu__bar:last-child {
    transform: translate(0, -$burgerBarOpen) rotate(-45deg);
}
