.type-preview {
  // @include col();
  // @include size(4);
  // @include size-lg(4);
  // @include size-sm(12);
  height: 616px;
  overflow: hidden;
  border-radius: 8px;
  position: relative;
  display: grid;

  & > * {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
  }

  width: min(30.875rem, 85vw);

  @include md-block {
    height: 292px;
    @include fp(16, 24);
  }

  @include sm-block {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
  &__button {
    margin-top: auto;
    margin-bottom: 2rem;
    margin-left: 1.5rem;
  }
}
