.modal {
  position: fixed;
  inset: 0;
  width: 100vw;
  min-height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: $modal;

  @media (max-width: 48rem) {
    overflow: auto;
  }
}
.modal__backdrop {
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1;
}

.modal__wrap {
  position: relative;
  background: #fff;
  box-shadow: 0 32px 64px rgba(17, 17, 17, 0.08);
  border-radius: 8px;
  cursor: initial;
  -webkit-overflow-scrolling: touch;
  pointer-events: all;
  padding: 0;
  z-index: 1;
  max-width: 100%;

  @media (min-width: 36rem) {
    padding: 2rem;
    margin: 3.5rem;
  }
}

.modal__wrapper {
  width: 100%;
  height: 100%;
  max-height: 100dvh;
  padding: 1rem;
  box-sizing: border-box;
  overflow: auto;

  @media (min-width: 36rem) {
    max-width: 75rem;
    max-height: 80dvh;
    padding: 2rem 4rem;
  }

  @media (min-width: 48rem) {
    padding: 3rem 5rem;
  }
}

.modal__close-button {
  display: grid;
  place-content: center;
  width: 2rem;
  height: 2rem;
  background-color: $white;
  cursor: pointer;

  @media (min-width: 48rem) {
    position: absolute;
    top: 1rem;
    right: -38px;
    border-radius: 8px;
    border: 1px solid $primary;
  }
}
