.files-for-downloading-section {
  padding-bottom: 140px;
}
.files-for-downloading {
  @include row-flex();
  row-gap: 10px;
  li {
    @include col();
    @include size(7);

    margin: 0 auto;
    padding-left: 0;
  }
  a {
    color: $red;
    text-decoration: underline;
  }
}