.cart-modal-footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  row-gap: 32px;
  align-items: center;
}

.cart-modal-footer__title {
  @include fp(20, 20);
}

.cart-modal-footer__total-price {
  @include fp(32, 20);
  font-family: $font-roboto;
}

.cart-modal-footer__button {
  width: 100%;
}
