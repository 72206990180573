.success-body-section {
  height: calc(100vh - 64px);
  @include md-block{
    height: calc(100vh - 37px);
  }

  @include sm-block{
  position: relative;
  }

  .container {
    height: 100%;
  }
}
.success-body {
  position: relative;
  @include row-flex();
  height: 100%;


  @include sm-block{
    position: inherit;
  }
}
.success-body__img {
  position: absolute;
  width: 60vw;
  top: 0;
  right: 42.7%;
  border-radius: 0;

  @include md-block{
    width: 49vw;
    right: 55.7%;
  }
  @include sm-block{
      width: 100%;
    right: 0;
  }
}
.success-body__content {
  position: relative;
  z-index: 1;
  @include col();
  @include size(4);
  @include shift-left(8);
  @include shift-lg-left(8);
   @include size-md(6);
  @include shift-md-left(6);
   @include size-sm(12);
  @include shift-sm-left(0);

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: $primary;
  text-align: center;
  row-gap: 16px;

  @include sm-block{
    padding: 20px;
    background-color: rgba($white, .7);
  }
}
.success-body__label {
  @include fp(16, 24);
}
.success-body__order-number {
  font-family: 'Anton', sans-serif;
  @include fp(28, 32);
}
.success-body__description {
  @include fp(16, 24);
}
.success-body__client-email {
  font-family: 'RobotoC', sans-serif;
  margin-left: 3px;
  text-decoration: underline;
}
.success-body__button {
  margin-top: 16px;
}
