@import "components";

.cart-wrapper {
  max-width: 660px;
  padding: 87px 0 146px;
}
.cart {
  display: flex;
  padding: 0 42px;

  @include sm-block{
    padding: 0 16px;
  }
}
.cart_product-preview-error {
  position: absolute;
  bottom: 0;
  padding: 5px 20px;
  background-color: $error;
  color: $white;
  left: 10px;
  right: 10px;
}
.cart__empty {
  position: absolute;
  @include center();

  p:first-letter{
    text-transform: capitalize
  }
}
.cart__list {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  width: 100%;
  margin-bottom: 2rem;
}
