@mixin anim($time: .3) {
  transition: #{$time}s ease-out all;
}

@mixin center-vert() {
  top: 50%;
  transform: translate(0, -50%);
}
@mixin center-gor() {
  left: 50%;
  transform: translate(-50%);
}
@mixin center() {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin fp($s, $h) {
  font-size: #{$s}px;
  line-height: #{$h}px;
}
@mixin wh($s) {
  width: #{$s}px;
  height: #{$s}px;
}

@mixin border($radius: 8, $color: $primary) {
  border: 1px solid #{$color};
  border-radius: #{$radius}px;
}