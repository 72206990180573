.popular-accessory-categories-section {
  padding: 89px 0 64px;
  background-color: $primary;
  overflow: hidden;

  @include md-block {
    padding: 64px 0;
  }

  @include sm-block {
    padding: 48px 0 64px;
  }

  &.light-mode {
    background-color: $white;
    h2 {
      color: $primary;
    }
  }
}
.popular-accessory-categories__image-wrapper {
  position: relative;
  width: 100%;
  height: 663px;
  margin-bottom: 156px;
  padding: 48px 56px;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;

  &::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 1px;
    background-color: $white;
    bottom: -64px;
    left: 0;
  }

  @include md-block {
    margin-bottom: 126px;
    height: 330px;
  }
  @include sm-block {
    padding: 32px 24px;
    height: 439px;
  }
}
.popular-accessory-categories__image {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.popular-accessory-categories__image-title {
  color: $white;
  max-width: 407px;
  width: 100%;
  margin-bottom: 40px;
  position: relative;
  z-index: 1;
  text-shadow: 2px 2px 8px hsl(0 0% 0% / 0.4);

  @include md-block {
    max-width: 241px;
  }
}
.popular-accessory-categories__buttons-wrapper {
  display: flex;
  position: relative;
  z-index: 1;

  @include md-block {
    flex-direction: column;
  }

  .button {
    max-width: 238px;

    &:not(:last-child) {
      margin-right: 24px;

      @include md-block {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
  }
}
.popular-accessory-categories__block-title {
  color: $white;
  margin-bottom: 48px;
}
.popular-accessory-categories__list-wrap {
  height: 316px;
  position: relative;
  margin-bottom: 40px;

  .swiper {
    height: 100%;
    overflow: visible;
  }
  .swiper-wrapper {
    height: 100%;
  }

  .swiper-slide {
    display: grid;
    align-items: end;
    height: 100%;
    width: 288px;
    // position: relative;
    border-radius: 8px;
    // padding: 24px 16px;
    box-sizing: border-box;

    // display: flex;
    // flex-direction: column;
    // justify-content: flex-end;
    overflow: hidden;

    & > * {
      grid-column: 1 / -1;
      grid-row: 1 / -1;
    }

    & > .button {
      margin-left: 1rem;
      margin-bottom: 1.5rem;
    }
  }
}
// .popular-accessory-categories__point-image {
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   top: 0;
//   left: 0;
// }
.popular-accessory-categories__point-button span {
  display: inline-block;
  max-width: 16ch;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.popular-accessory-categories__block-button {
  text-align: center;
  margin-top: 6rem;
}
