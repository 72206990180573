.page-head-section {
  padding: 75px 0 50px;
}

.page-head {
  padding-bottom: 28px;
  margin: 0 auto;
  text-align: center;
  position: relative;

  &.page-head__underline {
    &::after {
      position: absolute;
      content: '';
      width: 100%;
      bottom: 0;
      background-color: $primary;
      height: 1px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.page-head__subtitle {
  margin-bottom: 24px;
}

.page-head__title {
  @include fp(72, 72);
  width: 100%;

  @include md-block {
    @include fp(48, 48);
  }
  @include sm-block {
    @include fp(40, 48);
  }
}

.page-head__description {
  @include fp(16, 20);
  max-width: 903px;
  width: 100%;
  margin: 0 auto 16px;
}
