.camper-detailed-information-modal-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    & > :not(:last-child) {
        margin-bottom: 24px;
    }

    textarea {
        height: 160px;
    }
}
.camper-detailed-information-modal-form__name {
    width: calc(50% - 12px);

    @include sm-block{
        width: 100%
    };
}
.camper-detailed-information-modal-form__email {
    width: calc(50% - 12px);
    
    @include sm-block{
        width: 100%
    };
}