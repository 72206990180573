.post-content-section {
  padding: 15px 0 43px;
}
.post-content {
  @include row-flex();

  padding-bottom: 64px;
  margin-bottom: 40px;
  position: relative;

  &::after {
    position: absolute;
    content: "";
    width: calc(100% - 24px);
    height: 1px;
    background-color: $primary;
    bottom: 0;
    left: 12px;

    @include md-block {
      left: 9px;
      width: calc(100% - 18px);
    }
  }
}
.post-content__image-wrapper {
  @include col();
  width: 100%;
  position: relative;
  padding-bottom: 70px;

  @include md-block {
    padding-bottom: 90px;
  }

  @include sm-block {
    height: 223px;
    padding-bottom: 0;
    margin-bottom: 80px;
  }
}

.post-content__logo-wrapper {
  position: relative;

  @include col();
  @include size(3);
  @include size-lg(2);
  @include size-md(12);
  @include size-sm(12);
  @include size-xs(12);

  @include md-block {
    height: 0;
  }
}
.post-content__logo {
  top: 170px;
  position: sticky;
  transform: translate(70px, -100px);

  @include md-block {
    transform: translate(70px, -190px);
    width: 114px;
    left: 58px;
    bottom: 0;
  }
  @include sm-block {
    display: none;
  }
}
.post-content__article {
  @include col();
  @include size(7);
  @include size-lg(8);
  @include size-md(12);
  @include size-sm(12);
  @include size-xs(12);

  margin-left: auto;
  color: rgba($primary, 0.8);

  @include lg-block {
    margin-left: auto;
  }

  img {
    height: 470px;
    object-fit: cover;
  }
  button {
    margin-top: 40px;
  }
}
.post-content__wysiwyg {
}
.post-content__socials {
  @include col();
  @include size(12);
  @include size-lg(12);
  @include size-md(12);
  @include size-sm(12);
  @include size-xs(12);

  & > :first-child {
    margin-left: auto;
  }
}
