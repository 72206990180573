.accessories-description-section {
  padding-bottom: 140px;
}
.accessories-description {
  @include row-flex();
}
.accessories-description__wrapper {
  @include col();
  @include size(6);
  margin: 0 auto;
}
.accessories-description__text {

}
.accessories-description__table {
  margin-top: 40px;
}
.accessories-description__point {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;

  &:not(:first-child) {
    margin-top: 20px;
  }

    &::after {
      position: absolute;
      content: '';
      width: 100%;
      height: 1px;
      background-color: rgba($black, .2);
      bottom: 0;
      left: 0;
    }

}
.accessories-description__label {
  @include fp(20, 24);
}
.accessories-description__value {
  font-family: RobotoC, 'sans-serif';
  @include fp(20, 20);
}
