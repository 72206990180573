.accessories-detailed-information-section {
  padding: 48px 0 100px;
}

.accessories-detailed-information__wrapper {
  @include row-flex();
  justify-content: space-between;
  margin-top: 40px;
}

.accessories-detailed-information__camper-slider {
  @include col();
  @include size(6.5);
  @include size-lg(7);
  @include size-md(12);

  @include md-block {
    margin-bottom: 50px;
  }
}

.accessories-detailed-information__data {
  display: flex;
  flex-direction: column;
  @include col();
  @include size(5);
  @include size-md(12);
}

.accessories-detailed-information__product-price {
  margin-top: 24px;
}

.accessories-detailed-information__shipping-info-label {
  margin: 8px 0 24px;
  display: block;
  @include fp(14, 14);
  font-family: "RobotoC", sans-serif;

  a {
    margin-left: 5px;
    color: $red;
    @include fp(14, 14);
    font-family: "RobotoC", sans-serif;
    text-decoration: underline;
  }
}

.accessories-detailed-information__deliver-info {
  display: block;
  @include fp(18, 14);
  font-family: "RobotoC", sans-serif;
  margin-top: 24px;
}

.accessories-detailed-information__order-number-info {
  display: block;
  @include fp(18, 14);
  margin-top: 16px;
}

.accessories-detailed-information__order-number-label {
  font-family: "RobotoC", sans-serif;
}

.accessories-detailed-information__order-number {
  margin-left: 5px;
  font-family: "RobotoC", sans-serif;
}

.accessories-detailed-information__counter {
  margin-top: auto;
  margin-bottom: 24px;

  @include md-block {
    margin-top: 50px;
  }
}

.accessories-detailed-information__btn {
  user-select: none;
}
