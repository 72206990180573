.tabs {
  display: flex;
  border-top: 1px solid $primary;
  flex-wrap: wrap;

  // @include sm-block {
    // flex-direction: column;
    // border-top: none;
    // border-left: 1px solid $primary;
  // }
}
.tabs__tab {
  @include anim;
  @include fp(16, 24);

  padding: 20px;
  color: $primary;
  text-transform: uppercase;
  font-family: $font-roboto;
  cursor: pointer;

  @include sm-block {
    @include fp(14, 24);
    padding: 20px 8px;
  }
  @include xs-block {
    @include fp(12, 24);
    padding: 15px 8px;
  }

  &.active,
  &:hover {
    color: $white;
    background-color: $primary;

    & a {
      color: $white;
    }
  }
}
