@import "./components";
.team {
  padding-bottom: 128px;
  position: relative;

  &-section {
    padding: 40px 0;
  }

  &::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 1px;
    background-color: $primary;
    bottom: 0;
    left: 0;
  }
  &__title {
    @include fp(48, 48);
    font-family: "Anton", sans-serif;
    text-align: center;
    margin-bottom: 64px;
  }
  &__list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(16.125rem, 1fr));
    margin-top: 64px;
    row-gap: 86px;
    align-items: flex-start;

    @include md-block {
      row-gap: 20px;
    }
  }
}
