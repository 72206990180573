.filter-footer {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.filter-footer__filter-count {

}
.filter-footer__clear {
  cursor: pointer;
}