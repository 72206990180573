@import "./sections";

.about-us-page {
  background-color: red;
  &__page-head {
    padding-bottom: 0;
    &:after {
      display: none;
    }
  }
}
