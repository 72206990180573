@mixin h1() {
  font-family: $font-anton;
  @include fp(72, 72);

  @include md-block {
    @include fp(48, 48);
  }
  @include sm-block {
    @include fp(40, 40);
  }
}

@mixin h2() {
  font-family: $font-anton;
  @include fp(48, 56);
}

@mixin subtitle-1() {
  font-family: $font-anton;
  @include fp(32, 32);
}

@mixin subtitle-2() {
  font-family: $font-anton;
  @include fp(32, 32);
}

@mixin body-1() {
  font-family: $font-roboto;
  @include fp(20, 24);
}

@mixin body-2() {
  font-family: $font-roboto;
  @include fp(16, 20);
}

@mixin button() {
  font-family: $font-roboto;
  @include fp(18, 20);
}

@mixin tabs() {
  font-family: $font-roboto;
  @include fp(16, 16);
}
