.filters-section {
  padding: 0;
}

.filters {
  display: flex;
  align-items: center;
  position: relative;
  text-transform: uppercase;
  white-space: nowrap;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.filters__production-types {
  display: flex;
  align-items: center;
  margin-inline: auto;
  gap: 2.5rem;

  @include lg-block {
    margin-left: 0;
  }

  @include md-block {
    flex-wrap: wrap;
    gap: 0.875rem;
  }

  @include xs-block {
    justify-content: space-between;
    width: 100%;
  }
}

.filters__production-types-type {
  padding: 0 11px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  @include fp(16, 24);
  font-family: $font-roboto;
  @include anim;
  cursor: pointer;

  &.active, &:hover {
    -webkit-text-stroke: 1px $white;
    background-color: $primary;
    color: $white;
  }
}

.filters__options-button {
  display: flex;
  align-items: center;
  @include anim;
  cursor: pointer;

  @include sm-block {
    position: relative;
    top: auto;
    transform: translate(0);
  }
}

.filters__options-button-text {
  margin-left: auto;
  margin-right: 12px;
  color: $red;
}
