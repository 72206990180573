@import './sections';

.post-page__page-head-section {

    .page-head {
        text-align: start;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .page-head__subtitle{
        opacity: .3;
        cursor: pointer;
        font-family: 'RobotoC', sans-serif;
        svg {
            @include anim;
            transform: rotate(180deg)
        }

        &:hover {
            svg {
                transform: rotate(180deg) translate(2px)
            }
        }
    }
    .page-head__description {
        margin-left: 0;
    }
}
