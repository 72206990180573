.product-counter {
  user-select: none;
  width: 100%;
  display: flex;
  justify-content: space-between;
  border: 1px solid $primary;
  height: 40px;
  align-items: center;
  border-radius: 27px;
  @include fp(18, 20);
}
.product-counter__action {
  @include anim();
  cursor: pointer;
  position: relative;
  width: 50px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;


  &:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 2px;
    background-color: $primary;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &.product-counter__disabled {
    opacity: .3;
    cursor: auto;
  }
}
.product-counter__action_dec {
}
.product-counter__action_inc {

  &:before {
    content: '';
    position: absolute;
    width: 2px;
    height: 12px;
    background-color: $primary;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
.product-counter__count {

}