.text-center {text-align: center}
.text-right {text-align: right}

.text-white {color: white}
.text-black {color: black}

.line-clamp {
  --_lines: 1;
  display: -webkit-box;
  -webkit-line-clamp: var(--_lines);
  -webkit-box-orient: vertical;
  overflow: hidden;

  &-1 {
    --_lines: 1;
  }
  &-2 {
    --_lines: 2;
  }
  &-3 {
    --_lines: 3;
  }
  &-4 {
    --_lines: 4;
  }
  &-5 {
    --_lines: 5;
  }
}

.text-\[12rem\] {
  font-size: 12rem;
}

.text-lg {font-size: 1.125rem}
.text-xl {font-size: 1.25rem}
.text-2xl {font-size: 1.5rem}
.text-3xl {font-size: 1.875rem}
.text-4xl {font-size: 2.25rem}

// .whitespace-normal {white-space: normal}

.cursor-pointer {cursor: pointer}

.underline {text-decoration: underline}

.font-anton {font-family: $font-anton}
.font-roboto {font-family: $font-roboto}
.font-saira {font-family: $font-saira}

.font-semibold {font-weight: 600}
.font-bold {font-weight: 700}

.normal-case {text-transform: none}
