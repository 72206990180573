.mobileMenu {
  @include anim;
  position: fixed;
  background-color: $white;
  display: grid;
  place-content: center;
  width: 100%;
  height: 100%;
  max-height: 100dvh;
  box-sizing: border-box;
  overflow: hidden;
  z-index: $mobileMenu;
  padding-top: 30px;
  top: 64px;

  @include xs-block {
    // top: 14vh;
    height: 100dvh;
  }

  > .container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .mobileMenu__wrapper {
    overflow: scroll;
    padding-bottom: 50px;

    li + li {
      margin-top: 1rem;
    }
  }

  li {
    text-align: center;
  }

  a, .menu__link {
    @include anim;
    @include fp(40, 40);
    font-family: "Anton";
    text-transform: uppercase;
  }

  .mobileMenu__footer {
    display: flex;
    margin: auto 0 130px;

    li + li {
      margin-left: 20px;
    }
  }

  .mobileMenu_lines {
    @include anim(0.6s);
    position: absolute;
    bottom: 50px;
    max-width: 1440px;
    left: -60px;

    @include sm-block {
      left: -190px;
    }
  }

  & .submenu {
    margin-top: 0.5rem;
    box-shadow: none;

    & .submenu__item + .submenu__item {
      margin-top: 0.25rem;
    }

    & .menu__link {
      padding-block: 0.75rem;
      font-size: 1.5rem;
    }
  }
}
