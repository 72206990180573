.field-wrap {
    display: flex;
    position: relative;
    width: 100%;

    label {
        position: absolute;
        transition: all ease-out 0.3s;
        font-size: 15px;
        pointer-events: none;
        left: 16px;
        top: 18px;
        color: rgba($primary, 0.4);
        @include fp(15, 16);
        background-color: $white;
        padding: 0 5px;
    }

    input,
    textarea,
    select {
        width: 100%;
        height: 100%;
        background-color: transparent;
        border: 1px solid $primary;
        box-sizing: border-box;
        color: $primary;
        padding: 16px;
        @include fp(15, 16);

        .field-wrap-error & {
            border-color: $red;
        }

        &:focus ~ label,
        &:not(:placeholder-shown) ~ label,
        &:-webkit-autofill ~ label,
        &:-webkit-autofill ~ label,
        &:-webkit-autofill:hover ~ label,
        &:-webkit-autofill:focus ~ label {
            transform: translateY(-26px);
        }

        &:disabled {
            color: rgba($primary, 0.2);
            border: 1px solid rgba($primary, 0.2);
            & ~ label {
                color: rgba($primary, 0.2);
            }
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus {
            -webkit-box-shadow: 0 0 0px 1000px $white inset !important;
        }
    }

    textarea {
        resize: none;
    }

    &.field-wrap-error {
        input,
        textarea {
            border-color: $red;
        }

        label {
            transform: translateY(-26px);
            color: $red;
        }
    }

    .error {
        position: absolute;
        right: 0;
        color: $error;
        font-size: 12px;
        bottom: -16px;
    }
}
