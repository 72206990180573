@keyframes appear {
    from {
      transform: translateY(calc(100% + 20px));
    }
  
    to {
      transform: translateY(0);
    }
  }
  
  .toast-message {
    z-index: $toast;
    background: #1e1e1e;
    color: white;
    border-radius: 16px;
    position: fixed;
    bottom: 0;
    right: 0;
    animation: toast 3000ms ease;
    margin: 20px;
    transform: translateY(calc(100% + 20px));
    transition: transform 250ms ease;
    animation: appear 250ms ease;
  
    &.visible {
      transform: translateY(0);
    }
  
    &.type--success {
      background: $successShade;
      color: $success;
  
      .toast-message__icon,
      .toast-message__close {
        svg path {
          stroke: $success;
        }
      }
    }
  
    &.type--error {
      background: $errorShade;
      color: $error;
  
      .toast-message__icon,
      .toast-message__close {
        svg path {
          stroke: $error;
        }
      }
    }
  
    .toast-message__content {
      display: flex;
      padding: 24px;
      align-items: stretch;
    }
  
    .toast-message__icon {
      width: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
  
      svg path {
        stroke: white;
        width: 30px;
        height: 30px;
      }
    }
  
    .toast-message__text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 28px;
    }
  
    .toast-message__title {
      font-weight: bold;
    }
  
    .toast-message__close {
      width: 12px;
      margin-left: auto;
      cursor: pointer;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
  
      svg path {
        stroke: white;
        width: 12px;
        height: 12px;
      }
    }
  }
  