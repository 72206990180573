@import './Socials/Socials.scss';
@import './Tabs/Tabs.scss';
@import './ToastMessage/ToastMessage.scss';
@import './ContactsInPicture/ContactsInPicture.scss';
@import './PostPreview/PostPreview.scss';
@import './Article/Article.scss';
@import './ProductPreview/ProductPreview.scss';
@import './PreviewPrice/PreviewPrice.scss';
@import './PreviewCategories/PreviewCategories.scss';
@import './PreviewTitle/PreviewTitle.scss';
@import './CamperPreview/CamperPreview.scss';
@import './SubcategoryPreview/SubcategoryPreview.scss';
@import './DetailedInformationSlider/DetailedInformationSlider.scss';
@import './ProductGallery/ProductGallery.scss';
@import './ProductPrice/ProductPrice.scss';
@import './ProductName/ProductName.scss';
@import './WUSWUG/WUSWUG.scss';
@import './ProductAddToCart/ProductAddToCart.scss';
@import './Cart/Cart.scss';
@import './Fields/fields';

.error-page {
  display: grid;
  width: 100%;
  height: 80dvh;
  justify-items: center;
  align-content: center;
}
