.feature-box {
  background-color: $white;
  border: 1px solid $primary;
  border-radius: 100vw;
  height: 36px;
  padding: 0 1.25rem;
  display: inline-flex;
  align-items: center;
  align-self: flex-start;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.175;
  text-transform: uppercase;
  @include anim;

  &.dark {
    background-color: $primary;
    color: $white;
  }
  &.red {
    border: 1px solid $red;
    background-color: $red;
    color: $white;
  }
  &.hover {
    cursor: pointer;
  }

  svg > * {
    @include anim;
  }

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
}
.feature-box__detail {
  display: flex;
  align-items: center;
  gap: 0.75rem;

  &:not(:last-child) {
    margin-right: 1.125rem;
  }

  & > :not(:last-child) {
    margin-right: 0.25rem;
  }
}
