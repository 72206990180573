.checkout-payment-options {
  max-width: 546px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.checkout-payment-options__title {
  text-transform: lowercase;
  @include fp(20, 20);
  margin-bottom: 40px;
  font-family: 'RobotoC', sans-serif;
  align-self: flex-start;

  &:first-letter {
    text-transform: capitalize
  }
}
.checkout-payment-options__payment-types {
  display: flex;
  flex-wrap: wrap;
  row-gap: 15px;
  column-gap: 15px;
}
.checkout-payment-options__payment-type {
  width: 172px;
  height: 73px;
  border: 2px solid $gray-1;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  @include fp(20, 20);
  font-family: 'RobotoC', sans-serif;

  & .LazyLoad {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    object-fit: contain;
    width: 70%;
    height: 70%;
  }

  > :first-letter{
    text-transform: capitalize
  }

  &.active {
    border-color: $primary;
  }


}
.checkout-payment-options__next {
  margin-top: 50px;
}
.checkout-payment-options__back {
  cursor: pointer;
  display: block;
  margin: 20px auto 0;
  font-family: 'RobotoC', sans-serif;
  @include fp(18, 20);
  color: $gray-2;
}
