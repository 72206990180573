.contain {
  object-fit: contain;
}

.cover {
  object-fit: cover;
}

.left-2 {
  left: 0.5rem;
}

.right-2 {
  right: 0.5rem;
}

.overflow-x-hidden {
  overflow-x: hidden;
}
