.product-preview {
  container-type: inline-size;
  display: flex;
  row-gap: 14px;
  flex-direction: column;

  &__image-wrapper {
    position: relative;
    // display: grid;
    // place-content: center;
    width: 100%;
    max-height: 396px;
    aspect-ratio: 1;
    overflow: hidden;

    & .image {
      object-fit: contain;
    }
  }

  &__status-wrapper {
    position: absolute;
    top: 24px;
    left: 16px;
    display: flex;
    column-gap: 15px;

    .App_search-page & {
      @include sm-block {
        top: 0.25rem;
        left: 0.25rem;
      }
    }
  }

  &__status {
    padding: 6px 16px;
    box-sizing: border-box;
    height: auto;

    .App_search-page & {
      @include sm-block {
        padding: 0.1rem 0.3rem;
        border-radius: 4px;
        font-size: 0.5rem;
        line-height: 1.2;
      }
    }

    &.red::after {
      content: none;
    }
  }

  &__title {
    margin-top: auto;

    a {
      display: flex;
      align-items: center;
      margin-right: 8px;
      font-family: $font-anton;
      @include fp(32, 32);
      font-weight: 500;
    }

    @include lg-block {
      @include fp(24, 32);
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
  }

  &__price-info {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: auto;
  }

  &__price {
    @include fp(20, 20);
    font-family: 'RobotoC', sans-serif;
  }

  &__actions {
    display: flex;
    column-gap: 1rem;
  }

  @container (max-width: 16rem) {
    &__actions {
      column-gap: 0.5rem;

      & > * {
        padding-inline: 0.25rem;
        height: 1.75rem;
      }

      & svg {
        width: 1.25rem;
        height: 1.25rem;
      }
    }
  }

  @container (max-width: 18rem) {
    .preview-categories {
      margin-bottom: 0.25rem;
    }

    .preview-categories,
    .preview-categories a {
      font-size: 0.75rem;
      line-height: 1.2;
    }
  }

  @include sm-block {
    .App_search-page & {
      display: grid;
      grid-template-columns: 4.375rem 1fr auto;
      gap: 1.5rem;

      &__image-wrapper {
        max-height: 4.375rem;
        aspect-ratio: 1;
      }

      & .preview-title {
        margin-top: 0;
        margin-bottom: 0.5rem;

        & span {
          font-size: 0.875rem;
          font-weight: 500;
          line-height: 1.143;
        }

        & svg {
          display: none;
        }
      }

      & .product-preview__footer {
        margin-block: auto;
      }

      & .product-preview__price-info {
        display: grid;
        text-align: right;
      }

      & .preview-price-old {
        margin-right: 0;
      }

      & .preview-description {
        display: block;
      }

      & .preview-categories,
      & .product-preview__actions {
        display: none !important;
      }
    }
  }
}

.preview-description {
  display: none;
  font-size: 0.875rem;
  line-height: 1.143;
}
