$white: #fff;
$primary: #0E1F37;
$black: #000;
$red: #E70000;
$navy-blue: #1A3761;
$midnight-blue: #0E1F37;
$midnight-teal: #15253C;

$gray-1: #E1E1E1;
$gray-2: #6E7987;

$error: #db5c4d;
$errorShade: #f8f0ed;
$warning: #f5b534;
$warningShade: #fdf4ec;
$success: #1f6a6a;
$successShade: #eff7f7;

$font-roboto: 'RobotoC', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
$font-anton: 'Anton', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
$font-saira: 'SairaC', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;

// #1E3361
