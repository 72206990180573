.camper-detailed-information-modal {
  .modal__wrap-transparent-wrap {
    max-width: 1241px;
    padding: 60px;
    box-sizing: border-box;

    @include sm-block {
      padding: 30px 45px;
    }
    @include xs-block {
      padding: 15px 45px;
    }
  }

}
.camper-detailed-information__modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.camper-detailed-information__modal-title {
  @include fp(48, 69);
  margin-bottom: 25px;

  @include sm-block {
    @include fp(35, 40);
  }

  @include xs-block {
    @include fp(27, 30);
  }
}
.camper-detailed-information__content {
  display: flex;
  justify-content: space-between;
  width: 100%;

  @include md-block {
    flex-direction: column;
  }
}
.camper-detailed-information__left-col {
  max-width: 468px;
  height: 457px;
  margin-right: 40px;
  width: 100%;

  @include md-block {
    order: 1;
    max-width: 100%;
    margin-right: 0;
    margin-top: 50px;
  }
}
.camper-detailed-information__right-col {
  max-width: 424px;
  width: 100%;

  @include md-block {
    max-width: 100%;
  }
}
