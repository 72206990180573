.hero {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  justify-items: center;
  height: calc(100dvh - 4rem);
  overflow: hidden;

  &__background,
  &__content {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
  }

  &__background {
    position: absolute;
    inset: 0;
    height: 100%;
    z-index: -1;

    & img {
      object-fit: cover;
      width: auto;
      height: 100%;
    }

    & video {
      width: auto;
      height: 100%;

      @media (min-width: 75rem) {
        width: 100%;
        height: auto;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding-bottom: 4.5rem;
  }

  &__title {
    max-width: 18ch;
    margin-top: auto;
    margin-bottom: 2rem;
    color: $white;
    font-size: 5.625rem;
    line-height: 1.3;
    text-shadow: 0 0 8px hsl(0 0% 0% / 0.4);
    text-wrap: balance;

    @include sm-block {
      font-size: 3rem;
    }
  }

  &__cta {
    display: flex;
    flex-wrap: wrap;
    gap: 1.25rem;

    &-button {
      display: inline-flex;
      width: auto;
      padding-right: 5rem;

      @include sm-block {
        width: 100%;
        padding-right: 3rem;
      }
    }

    // @include sm-block {
    //   flex-direction: column;
    //   margin-bottom: auto;
    // }
  }
}
