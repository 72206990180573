.team {
  &__list {
    column-gap: 1.5rem;
  }

  &__point {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;

    border-radius: 8px;
    color: $white;
    font-weight: 700;
    // overflow: hidden;

    & > * {
      grid-column: 1 / -1;
      grid-row: 1 / -1;
    }
  }

  &__image {
    height: auto;
    margin-bottom: 16px;
    object-fit: unset;
    border-radius: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  &__description {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 3rem;
    margin-block: auto 0;
    padding: 0 0.75rem 0 0.5rem;
    background-color: hsl(215 48% 16% / 0.96);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &__contact {
    position: relative;
    padding: 0.5rem;
    color: $red;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &-hint {
      display: none;
      position: absolute;
      bottom: calc(100% - 2px);
      right: 0;
      translate: 25% 0;
      width: max-content;
      padding: 0.5rem 0.75rem;
      border-radius: 100vw;
      background-color: $red;
      color: $white;
      font-size: 1.125rem;
      line-height: 1.16;
      opacity: 0;
      transition: all 0.3s ease-in-out;

      & > a {
        color: $white;
      }
    }

    &:is(:hover),
    &[data-visible='true'] {
      scale: 1.05;

      & .team__contact-hint {
        display: block;
        opacity: 1;
      }
    }
  }
}

