.preview-categories {

  a, span {
    position: relative;
    display: inline;
    color: rgba($primary, 0.5);
    text-transform: uppercase;

    &:not(:last-child) {
      padding-right: 4px;
      margin-right: 4px;

    }
  }
}
