.cart-product-quantity-controller {
  user-select: none;
  max-width: 104px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include fp(18, 20);
}
.cart-product-quantity-controller__action {
  @include anim();
  cursor: pointer;
  position: relative;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: 1px solid $gray-1;

  &:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 2px;
    background-color: $primary;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &.cart-product-quantity-controller__disabled {
    opacity: .3;
    cursor: auto;
  }
}
.cart-product-quantity-controller__action_dec {

}
.cart-product-quantity-controller__action_inc {
  &:before {
    content: '';
    position: absolute;
    width: 2px;
    height: 12px;
    background-color: $primary;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}