.accessory-preview-slider-section {
  overflow: hidden;
  padding: 64px 0;
}

.accessory-preview-slider {

}

.accessory-preview-slider__title {
  margin-bottom: 48px;
}

.accessory-preview-slider__list {

  .swiper {
    height: 100%;
    overflow: visible;
  }

  .swiper-wrapper {
    height: 100%;
  }

  .swiper-slide {
    height: auto;
    max-width: 432px;
    width: 100%;
  }
}