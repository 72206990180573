.rent-page-detailed-data-section {
    padding: 60px 0 200px;

    @include md-block{
        padding-top: 30px;
    };
    @include sm-block{
        padding: 20px 0 50px;
    };
}
.rent-page-detailed-data {
    @include row-flex();

    @include md-block{
        &>:not(:last-child) {
            margin-bottom: 64px;
        }
    };
}
.rent-page-detailed-data__col {
    @include col();
    @include size(6);
    @include size-lg(6);
    @include size-md(12);

    &>:not(:last-child) {
        margin-bottom: 80px;
    }
}
.rent-page-detailed-data__category {
    &>:not(:last-child) {
       margin-bottom: 16px;
    }
}
.rent-page-detailed-data__title {
    @include fp(32, 40);
    font-weight: 100;
    font-family: 'Anton';
}
.rent-page-detailed-data__sub-title {
    display: inline-block;
    font-family: 'RobotoC';
    @include fp(20, 24);

}
.rent-page-detailed-data__list {
    &>:not(:last-child) {
        margin-bottom: 10px;
    }
}
.rent-page-detailed-data__point {

}
