.red-quantity {
  @include fp(12, 15);
  font-family: 'RobotoC', sans-serif;
  position: absolute;
  right: -4px;
  top: 0;
  background-color: $red;
  color: $white;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  text-align: center;
}
