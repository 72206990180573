.camper-equipment-section {
  padding: 60px 0 160px;
}
.camper-equipment__block {
  & + li {
    margin-top: 72px;
  }
}
.camper-equipment__block-header {
  margin-bottom: 40px;
  display: flex;
  align-items: center;
}
.camper-equipment__title {
  @include fp(24, 32);
  font-family: $font-saira;
  font-weight: 100;
  margin-right: 24px;
  white-space: nowrap;
}
.camper-equipment__block-header-line {
  width: 100%;
  height: 1px;
  background-color: $primary;
}
.camper-equipment__point {
  font-family: $font-roboto;
  @include fp(20, 24);
  padding-left: 20px;
  position: relative;
  // max-width: 90vw;

  // @include sm-block {
  //   max-width: 70vw;
  // }

  &::after {
    position: absolute;
    content: "";
    width: 8px;
    height: 8px;
    background-color: $red;
    border-radius: 100%;
    left: 0;
    top: 8px;
  }
}

.equipment__description {
  max-height: 1.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: max-height 0.3s ease;

  &.open {
    max-height: 100%;
  }
}

.equipment__more {
  margin-top: 0.5rem;
}
