.rent-page-booking-section {
    padding: 60px 0;
}
.rent-page-booking {
    display: flex;
    justify-content: space-between;

    @include md-block{
        flex-direction: column
    };
}
.rent-page-booking__calender {
    max-width: 624px;
    width: 100%;

    @include lg-block{
        max-width: 514px;
    };
    @include md-block{
        max-width: none
    };
}
.rent-page-booking__title {
    margin-bottom: 32px;
    @include fp(32, 40);
    font-family: 'Anton';
    font-weight: 100;

}
.rent-page-booking__image {
    height: 467px;
    border-radius: 8px;
    border: 1px solid $primary;
    overflow: hidden;

    @include sm-block{
        height: 257px;
    };
}
.rent-page-booking__prices {
    max-width: 520px;
    width: 100%;

    @include lg-block{
        max-width: 450px;

    };
    @include md-block{
        margin-top: 64px;
        max-width: none
    };
}
.rent-page-booking__price-list {
    display: inline-flex;
    flex-direction: column;
    margin-bottom: 24px;

    &>:not(:last-child) {
        margin-bottom: 16px;
    }
}
.rent-page-booking__price {
    background: $primary;
    border-radius: 48px;
    padding: 12px 24px;
    color: $white;
}
.rent-page-booking__price-months {
    font-family: 'RobotoC';
    margin-right: 5px;
}
.rent-page-booking__price-info {
    font-family: 'RobotoC';
}
.rent-page-booking__price-updates {

}
.rent-page-booking__price-update {

}
.rent-page-booking__price-update {

}
