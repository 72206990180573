.write-us {
    max-width: 253px;
    width: 100%;

}
.write-us__title {
}
.write-us__list {
}
.write-us__point {
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
        margin-bottom: 27px;
    }
}
.write-us__label {
    color: rgba($white, 0.5);
    @include fp(16, 19);
    margin-bottom: 4px;
    font-family: $font-roboto;
    @include sm-block{
        @include fp(16, 19);

    };
}
.write-us__email {
    @include fp(20, 23);
    font-family: $font-roboto;
    @include sm-block{
        @include fp(18, 21);

    };
}
