.article {
  @include col();
  @include size(7);
  @include size-lg(8);
  @include size-md(12);
  @include size-sm(12);
  @include size-xs(12);

  margin-left: auto;

  @include lg-block{
    margin-left: auto;
  };

  img {
    height: 470px;
    object-fit: cover;

  }
  button {
    margin-top: 40px;
  }
}