.accessories-section {
  padding: 90px 0;
}
.accessories {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: 96px 24px;

  @media (min-width: 480px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(var(--_columns, 3), minmax(0, 1fr));
  }

  .product-preview {
    &__image-wrapper {
      // @include sm-block {
      //   height: 380px;
      // }
      // @include xs-block {
      //   height: 90vw;
      // }
      & > img {
        aspect-ratio: 1 / 1;
      }
    }
  }

  @include sm-block {
    .App_search-page & {
      grid-template-columns: minmax(0, 1fr);
      gap: 2rem;
    }
  }
}

.App_search-page .accessories-section{
  padding-block: 0;
}
