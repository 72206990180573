.info {
  max-width: 266px;
  width: 100%;

  @include sm-block {
    max-width: 163px;
  }

  &__link {
    font-size: 1rem;
    line-height: 1.2;

    @include sm-block {
      @include fp(16, 24);
    }
  }

  &__point {
    & + & {
      margin-top: 1.25rem;
    }
  }
}
