@import "./components";

.news-slider-section {
  overflow: hidden;
  padding-top: 96px;
  position: relative;

  @include md-block {
    padding-top: 85px;
  }

  @include sm-block {
    padding-top: 48px;
  }
}
.news-slider-section__ForestSvg {
  position: absolute;
  transform: translate(-50%);
  top: 20px;
  left: calc(50% + 460px);

  @include md-block {
    width: 398px;
    top: 10px;
    left: calc(50% + 250px);
  }
  @include sm-block {
    display: none;
  }
}
.news-slider {
  position: relative;
  padding-bottom: 65px;

  // @include sm-block {
  //   padding-bottom: 48px;
  // }

  &::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 1px;
    background-color: $black;
    bottom: 0;
    left: 0;
  }
  &.hide-line {
    &::after {
      display: none;
    }
  }
}

.news-slider__swiper-wrap {
  height: 642px;
  position: relative;
  margin-top: 40px;

  @include md-block {
    height: 389px;
  }

  .swiper {
    height: 100%;
    overflow: visible;
  }
  swiper-container,
  .swiper-wrapper {
    height: 100%;
  }
  swiper-slide,
  .swiper-slide {
    height: 100%;
    width: 496px;

    @include md-block {
      width: 326px;
    }
  }

  .post-preview__content {
    max-width: 380px;

    @include md-block {
      max-width: 321px;
    }
  }
}
