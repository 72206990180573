$column-gap: 24px;
$row-gap: 24px;
$mobile-row-height: 260px;

.product-gallery-section {
    padding-bottom: 40px;
}
.product-gallery {
}
.product-gallery-template {
    img {
        border-radius: 8px;
        overflow: hidden;
    }

    &:not(:last-child) {
        margin-bottom: $row-gap;
    }
}
.product-gallery-template_1 {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 578px;
    grid-column-gap: $column-gap;
    grid-row-gap: $row-gap;

    @include sm-block {
        grid-template-rows: $mobile-row-height;
    }

    img:nth-child(1) {
        grid-area: 1 / 1 / 2 / 2;
    }
}
.product-gallery-template_2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 500px;
    grid-column-gap: $column-gap;
    grid-row-gap: $row-gap;

    @include md-block {
        grid-template-rows: 370px;
    }

    @include sm-block {
        grid-template-rows: repeat(2, $mobile-row-height);
    }

    img:nth-child(1) {
        grid-area: 1 / 1 / 2 / 2;

        @include sm-block {
            grid-area: 1 / 1 / 2 / 3;
        }
    }
    img:nth-child(2) {
        grid-area: 1 / 2 / 2 / 3;

        @include sm-block {
            grid-area: 2 / 1 / 3 / 3;
        }
    }
}

.product-gallery-template_3 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 500px 578px;
    grid-column-gap: $column-gap;
    grid-row-gap: $row-gap;

    @include md-block {
        grid-template-rows: 370px 578px;
    }

    @include sm-block {
        grid-template-rows: repeat(3, $mobile-row-height);
    }

    img:nth-child(1) {
        grid-area: 1 / 1 / 2 / 2;

        @include sm-block {
            grid-area: 1 / 1 / 2 / 3;
        }
    }
    img:nth-child(2) {
        grid-area: 1 / 2 / 2 / 3;

        @include sm-block {
            grid-area: 2 / 1 / 3 / 3;
        }
    }
    img:nth-child(3) {
        grid-area: 2 / 1 / 3 / 3;

        @include sm-block{
            grid-area: 3 / 1 / 4 / 3;
        };
    }
}
.product-gallery-template_4 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 390px 164px 472px;
    grid-column-gap: $column-gap;
    grid-row-gap: $row-gap;

    @include md-block {
        grid-template-rows: 290px 64px 222px;
    }

    @include sm-block{
        grid-template-rows: repeat(4, 260px);
    };

    img:nth-child(1) {
        grid-area: 1 / 1 / 3 / 2;

        @include sm-block{
            grid-area: 1 / 1 / 2 / 3;
        };
    }
    img:nth-child(2) {
        grid-area: 1 / 2 / 2 / 3;

        @include sm-block{
            grid-area: 2 / 1 / 3 / 3;
        };
    }
    img:nth-child(3) {
        grid-area: 3 / 1 / 4 / 2;

        @include sm-block{
            grid-area: 3 / 1 / 4 / 3;
        };
    }
    img:nth-child(4) {
        grid-area: 2 / 2 / 4 / 3;

        @include sm-block{
            grid-area: 4 / 1 / 5 / 3;
        };
    }
}

.div1 { grid-area: 1 / 1 / 2 / 3; }
.div2 { grid-area: 2 / 1 / 3 / 3; }
.div3 { grid-area: 3 / 1 / 4 / 3; }
.div4 { grid-area: 4 / 1 / 5 / 3; }
