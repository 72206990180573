.modal-side-view {
  height: 100dvh;
  position: fixed;
  max-width: 391px;
  width: 100%;
  top: 0;
  right: 0;
  background-color: $white;
  border-left: 1px solid $primary;
  padding: 87px 0 53px;
  box-sizing: border-box;
  z-index: $filterOptions;
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 0.5s ease, transform 0.5s ease;

  @include sm-block {
    max-width: 100%;
  };

  &.open {
    opacity: 1;
    transform: translateX(0%);
  }
}

.modal-side-view__close-button {
  top: 30px;
  right: 20px;
}
.modal-side-view__wrapper {
  height: 100%;
  width: 100%;
  overflow: scroll;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
.modal-side-view__white-shadow {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 0) 100%);
  height: 200px;
  pointer-events: none;
}


.modal-side-view__footer {
  position: absolute;
  width: 100%;
  padding: 22px 42px;
  background-color: $primary;
  color: $white;
  font-family: 'RobotoC', serif;
  @include fp(18, 20);
  display: flex;
  bottom: 0;
  right: 0;
  box-sizing: border-box;
}
