@import "components";

.detailed-information-slider {

}
.detailed-information-slider-hero-wrapper {
    position: relative;
    height: 450px;
    
    @include sm-block {
        height: 234px;
    }
}
.detailed-information-slider-hero {
    cursor: pointer;
    border-radius: 8px;
    height: 100%;

    img {
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
}
.detailed-information-slider-navigation {
    position: absolute;
    top: 50%;
    cursor: pointer;
    z-index: 1;

    @include sm-block{
        display: none;
    };
}
.detailed-information-slider-prev-arrow {
    left: -15px;
    transform: translate(0, -50%);

    @include lg-block{
        left: -9px;
    };

    @include md-block{
        left: -15px;
    };
}
.detailed-information-slider-next-arrow {
    right: -15px;
    transform: translate(0, -50%) rotate(180deg);

    @include lg-block{
        right: -9px;
    };

    @include md-block{
        right: -15px;
    };
}
.detailed-information-slider-thumbs {
    margin-top: 20px;

    @include sm-block{
        margin-top: 24px;
    };

    .swiper-slide {
        border-radius: 100%;
        overflow: hidden;
        height: 80px;
        width: 80px !important;
        border: 1px solid transparent;
        @include anim;

        @include sm-block{
            width: 42px !important;
            height: 42px
        };

        &.swiper-slide-thumb-active {
            border: 1px solid $primary;
        }

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
}
