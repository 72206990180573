// .service-info-section {
//   padding: 50px 0 160px;

//   @include lg-block {
//     padding: 50px 0;
//   }

//   @include sm-block {
//     padding-top: 20px;
//   }
// }
.service-info {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));

}
.service-info__col {
  .service-info__big-image {
    display: none;
    height: 500px;
    margin-bottom: 40px;

    @include md-block {
      display: block;
      height: 400px;
    }
  }
}
// .service-info__description {
//   margin-bottom: 80px;
//   @include md-block {
//     margin-bottom: 64px;
//   }
//   p {
//     &:not(:last-child) {
//       margin-bottom: 15px;
//     }
//   }
// }

.service-info__pic-logo {
  position: relative;
  height: 317px;
  max-width: 489px;
  width: 100%;
  margin-bottom: 1rem;
  margin-right: auto;
  // padding-bottom: 95px;
  // margin: 0 auto 38px;

  @include md-block {
    margin-right: 0;
    margin-bottom: 0;
  }

  .image {
    overflow: hidden;
    border-radius: 8px;
  }
  .circle-black-logo-svg {
    position: absolute;
    bottom: 0;
    left: -50px;
  }
}

.service-info__title {
  @include fp(32, 40);
  margin-bottom: 34px;
}
.service-info__list {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 0.5rem;

  @include sm-block {
    grid-template-columns: minmax(0, 1fr);
  }

  &--col {
    grid-template-columns: minmax(0, 1fr);
  }

  & > :first-child {
    &::before {
      position: absolute;
      content: "";
      height: 100%;
      background-color: $red;
      width: 1px;
      left: 0;
      top: 0;
    }
  }
  &:not(.service-info__list--col) > :nth-child(2) {
    &::before {
      position: absolute;
      content: "";
      height: 100%;
      background-color: $red;
      width: 1px;
      left: 50%;
      top: 0;

      @include sm-block {
        display: none;
      }
    }
  }
}
.service-info__point {
  padding-left: 16px;
  box-sizing: border-box;

  @include fp(20, 24);

  &.red {
    color: $red;
  }
}
.service-info__big-image-wrap {
  max-width: 601px;
  width: 100%;
  position: relative;

  @include lg-block {
    max-width: 468px;
  }

  @include md-block {
    display: none;
  }
}
.service-info__big-image {
  height: 100%;
  max-height: 897px;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;

  @include lg-block {
    max-height: 784px;
  }

  @include md-block {
    max-height: 400px;
  }
}
.service-info__road-svg {
  position: absolute;
  bottom: -220px;
  left: -150px;

  @include lg-block {
    bottom: 40px;
    left: -258px;
  }
}
.service-info__prices-block {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 112px;

  @include lg-block {
    margin-top: 50px;
  }
  @include md-block {
    margin-top: 64px;
  }
  @include sm-block {
    flex-direction: column;
    align-items: flex-start;
  }
}
.service-info__prices-block-image {
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
}
.service-info__price-info {
  max-width: 520px;
  width: 100%;

  @include lg-block {
    max-width: 460px;
  }
  @include md-block {
    max-width: 300px;
  }
  @include sm-block {
    display: flex;
    flex-direction: column;
    max-width: none;
    margin-top: 30px;
  }
}
.service-info__prices {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 24px;
}
.service-info__price {
  padding: 12px 24px;
  background: $primary;
  color: $white;
  border-radius: 48px;
  box-sizing: border-box;

  @include sm-block {
    width: 100%;
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}
.service-info__prices-more-info {
  a {
    font-family: $font-roboto;
  }
}
