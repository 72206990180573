@import "./components";

.feedback-section {
  padding: 80px 0;

  @include sm-block {
    padding: 40px 0;
  }
}
@media (min-width: 64rem) {
  .feedback {
    display: grid;
    grid-template-columns: 1fr 1fr auto;
  }
}

.App_page h2,
.feedback__title {
  @include fp(48, 56);

  @include sm-block {
    @include fp(28, 38);
  }
}

.feedback__right-col {
  max-width: 574px;
  width: 100%;
  margin-right: 34px;

  @include lg-block {
    margin-right: 0;
    order: 3;
    max-width: none;
    height: 500px;
    margin-top: 50px;
  }
}
.feedback-section__matka-suvilad-svg {
  @include lg-block {
    order: 2;
  }

  @include sm-block {
    display: none;
  }
}
