.checkout-order-stages-names {
  margin: 32px 0 40px;
  display: flex;
  column-gap: 72px;
  justify-content: center;

  li {
    @include fp(16, 24);
    font-family: 'RobotoC', sans-serif;
    text-transform: uppercase;
    color: rgba($primary, .4);

    &.active {
      color: $primary;
    }

    &:not(:last-child) {
      position: relative;


      &:after {
        content: '';
        position: absolute;
        width: 40px;
        height: 1px;
        background-color: $primary;
        right: -56px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

}
