.subcategories-preview-section {
  padding: 50px 0 0;
  margin-bottom: 50px;
}

.subcategories-preview {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(9.125rem, max(40vw, 6rem)), 1fr));
  gap: 3rem 1.5rem;
  position: relative;
  padding-bottom: 90px;

  @include sm-block {
    padding-bottom: 40px;
  }

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: $primary;
    left: 0;
    bottom: 0;
  }

  // .subcategory-preview {
  //   // @include col();
  //   // @include size(1.5);
  //   // @include size-lg(2);
  //   // @include size-md(3);
  //   // @include size-sm(6);
  //   // @include size-xs(12);

  //   .subcategory-preview__image {
  //     @include xs-block {
  //       height: 80vw;
  //     }
  //   }
  // }
}
