.contacts-in-picture {
    width: 100%;
    aspect-ratio: 1;
    overflow: hidden;
    @include border;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.contacts-in-picture__image {
    width: 100%;
    height: 100%;
}
.contacts-in-picture__information {
    position: relative;
    z-index: 10;
    width: 372px;
    max-width: 100%;
    padding: 16px 24px;
    box-sizing: border-box;
    @include border(4);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $white;

    & > :not(:last-child) {
        margin-bottom: 8px;
    }
}
.contacts-in-picture__title {
    text-align: center;
    font-family: 'Anton';
    font-weight: 100;
    @include fp(20, 24);

}
.contacts-in-picture__link {

}
