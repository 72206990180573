.actions {
    display: flex;
    align-items: center;
    margin-left: auto;

    > :not(:first-child) {
        margin-left: 12px;
    }
    
    .lang {
        margin-left: 39px;
    }   
}