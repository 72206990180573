.camper-specifications-section {
    padding-bottom: 133px;
}
.camper-specifications {
    max-width: 660px;
    margin: 0 auto;

    @include lg-block{
        max-width: 458px;
    };
}
.camper-specifications__point {
    & + li {
        position: relative;
        margin-top: 20px;
        padding-top: 20px;

        &::after {
            position: absolute;
            content: '';
            width: 100%;
            height: 1px;
            background-color: $black;
            top: 0;
            left: 0;
        }
    }
}
.camper-specifications__specification-wrapper {
    display: flex;
    position: relative;
    padding-right: 58px;
}
.camper-specifications__label {
    @include fp(20, 24);
}
.camper-specifications__data {
    margin-left: auto;
    font-family: 'RobotoC';
    @include fp(20, 20);
}
.camper-specifications__question-mark-svg-wrapper {
    @include anim;

    position: absolute;
    background-color: $primary;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    cursor: pointer;
    right: 0;
    top: calc(50% - 4px);
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        background-color: $red;
    }
}
.camper-specifications__question-mark-svg {
    margin-right: -1px;
}
.camper-specifications__extra-data {
    position: absolute;
    top: 50%;
    transform: translateY(calc(-50% - 4px));
    width: 273px;
    padding: 16px;
    border-radius: 8px;
    background-color: $primary;
    color: $white;
    left: calc(100% + 16px);

    &::after {
        position: absolute;
        content: '';
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;

        border-right: 8px solid $primary;
        left: -9px;
        top: 50%;
        transform: translateY(-50%);
    }
}
