.enlarged-image-modal {

  .modal__wrap-transparent-wrap {
    max-height: 1000px;
  }
  .modal__wrap {
    @include md-block{
      padding: 34px 18px 40px;
    }
  }
  .modal__close-button {
    right: 18px;
  }
  .modal__wrapper {
    padding: 0;
  }
  .modal__content {
    height: 100%;
  }
  .image {
    object-fit: contain;
  }
}