.checkout-order-stages {
  padding-block: 1.5rem 5.625rem;

  @media (min-width: 48rem) {
    padding-block: 3.5rem 5.625rem;
  }

  @include col();

  @media (min-width: 768px) {
    @include size(7);
  }
}
