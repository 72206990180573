.call-to-action-section {
    padding: 95px 0 0;

    @include sm-block{
        padding: 80px 0 0;
    };
}
.call-to-action {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .circle-transparent-logo-svg {
        position: absolute;
        left: -60px;
        top: 40px;

        @include md-block{
            width: 160px;
        };

        @include md-block{
            display: none;
        };
    }
}
.call-to-action__title {

}
.call-to-action__description {
    margin-top: 12px;
    max-width: 604px;
    width: 100%;
    @include fp(16, 20);

}
.call-to-action__button {
    margin-top: 36px;
    min-width: 232px;
    width: fit-content;
    padding-right: 60px;

    @include sm-block{
        width: auto;
    };
}
