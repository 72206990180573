.our-story-section {
  padding-top: 100px;

  @include md-block {
    padding-top: 48px;
  }
}
.our-story {
  @include row-flex();
  position: relative;
  padding-bottom: 0;

  @include sm-block {
    padding-bottom: 60px;
  }

  & .article .image {
    object-fit: contain;
  }

  & .LazyLoad {
    width: 100%;
    height: auto;
  }
}
.our-story__col {
  // @include col();
  // @include size(6);
  // @include size-lg(6);
  // @include size-md(12);
  position: relative;
  z-index: 1;
  // padding-right: 50px;

  &.article {
    margin-block: 0;
    width: auto;
  }

  // & .image {
  //   margin-inline: auto;
  //   margin-block: 0
  // }

  @include md-block {
    padding-right: 0;
  }

  & > :not(:last-child) {
    margin-bottom: 30px;

    @include md-block {
      margin-bottom: 48px;
    }
  }

  p {
    span {
      background-color: rgba($white, 0.5);
      padding: 3px;
    }
  }

  img {
    width: 489px;
    height: auto;
    // height: 317px;
    // margin-left: auto;
    // margin-top: 80px;

    // @include md-block {
    //   margin-top: 0;
    // }
  }
  &.article img {
    margin-bottom: 80px;
  }
}
.our-story__image-wrapper {
  position: relative;
  @include col();
  @include size(6);
  @include size-lg(6);
  @include size-md(12);
  z-index: 1;

  @include md-block {
    margin-top: 48px;
    height: 400px;
  }
}

.our-story__image {
  object-fit: contain;
}

@media (min-width: 1100px) {
  .our-story__image-wrapper .LazyLoad {
    position: sticky;
    top: 50px;
  }
  .our-story__image {
    position: sticky;
    top: 50px;
    height: 897px;
    max-height: 80dvh;
  }
}

.our-story__road {
  position: absolute;
  bottom: 22px;
  right: 0;

  @media (max-width: 768px) {
    display: none;
  }

  @media (min-width: 94rem) {
    bottom: 73px;
    right: auto;
    left: 12px;
  }
}
