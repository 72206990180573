.subcategory-preview {
  text-align: center;

  &__image {
    width: 100%;
    height: auto;
    // height: 146px;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    border-radius: 8px;
  }

  &__name {
    text-decoration: underline;
    margin-top: 16px;
    @include fp(16, 24);
    font-family: 'RobotoC', sans-serif;
    text-transform: inherit;
  }
}
