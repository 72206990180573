.camper-preview {
  flex-direction: column;
  display: flex;

  @include sm-block {
    .App_search-page & {
      display: grid;
      grid-template-columns: 4.375rem 1fr;
      gap: 1.5rem;

      & .camper-preview {
        &__details {
          display: grid;
          grid-template-columns: 1fr auto;
          gap: 1.5rem;
          margin-top: 0;

          &-header {
            display: none;
          }

          & .preview-title {
            margin-top: 0;
            margin-bottom: 0.5rem;

            & span {
              font-size: 0.875rem;
              font-weight: 500;
              line-height: 1.143;
            }

            & svg {
              display: none;
            }
          }

          & .camper-preview__price-info {
            margin-block: auto;
          }
        }
      }

      & .feature-box {
        display: none;
      }
    }
  }
}
.camper-preview__header {
  position: relative;
  display: grid;
  place-content: center;
  // max-height: 396px;
  border-radius: 8px;
  // overflow: hidden;
  width: 100%;
  box-sizing: border-box;

  & .camper-point__feature-box {
    position: absolute;
    top: -1rem;
    left: 1rem;
  }

  & .camper__plan {
    position: absolute;
    bottom: -1rem;
    left: 1rem;
    cursor: pointer;
  }

  &-overlay {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 1rem;
    margin-top: -1rem;
    height: calc(100% + 2rem);
  }
}
.camper-preview__header-image {
  width: 100%;
  height: auto;
  object-fit: contain;

  &--map {
    position: absolute;
    inset: 0;
    display: grid;
    place-content: center;
    background-color: white;
    overflow: hidden;
  }
}

.camper-preview__feature-box {
  position: relative;
  z-index: 1;

  &.camper-preview__feature-box-bottom {
    margin-top: auto;
  }
}

.camper-preview {
  position: relative;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: subgrid;
  grid-row: span 2;
  margin-bottom: 4rem;

  @media (max-width: 744px) {
    .App_search-page & {
      grid-row: span 1;
      margin-bottom: 0;
    }
  }

  @media (min-width: 745px) {
    grid-row: span 2;
    margin-bottom: 5rem;
  }

  &__details {
    margin-top: 2rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    & > :not(:last-child) {
      margin-bottom: 16px;
    }
  }
  &__details-header {
    display: flex;
    align-items: center;
  }
  &__categories {
    display: flex;
    align-self: flex-start;
    align-items: center;
  }
  &__category {
    color: rgba($primary, 0.5);
    text-transform: uppercase;
    position: relative;

    &:not(:last-child) {
      padding-right: 4px;
      margin-right: 4px;

      &::after {
        position: absolute;
        content: "";
        height: 16px;
        width: 1px;
        background: rgba($primary, 0.5);
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
      }
    }
  }

  &__status {
    margin-left: auto;

    font-family: "SairaC";
    @include fp(16, 24);
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    background-color: $primary;
    color: $white;
    border-radius: 4px;
  }

  &__price-info {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: auto;
  }

  &__leasing {
    margin-left: auto;

    @include lg-block {
      display: flex;
      flex-direction: column;
    }
    @include xs-block {
      width: 100%;
      margin-top: 10px;
      margin-left: 0;
    }
  }
  &__leasing-label {
    @include fp(14, 14);
    font-family: $font-roboto, sans-serif;
    margin-right: 3px;

    @include lg-block {
      @include fp(12, 14);
    }
  }
  &__leasing-price {
    @include fp(14, 14);
    font-family: $font-roboto, sans-serif;

    @include lg-block {
      align-self: flex-end;
    }
    @include xs-block {
      align-self: flex-start;
    }
  }
}
