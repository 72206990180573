.site-map {
    max-width: 157px;
    width: 100%;

    @include sm-block{
        max-width: 126px;
    };
}
.site-map__title{

}
.site-map__lis{

}
.site-map__point{
    &:not(:last-child) {
        margin-bottom: 16px;
    }
}
.site-map__link{
    @include fp(20, 32);

    @include sm-block{
        @include fp(16, 16);
        
    };
}