.mx-auto {margin-left: auto; margin-right: auto}
.mx-0 {margin-left: 0; margin-right: 0}
.mx-4 {margin-left: 1rem; margin-right: 1rem}

.my-10 {margin-top: 2.5rem; margin-bottom: 2.5rem}

.mt-5 {margin-top: 1.25rem}
.mt-8 {margin-top: 2rem}
.mt-12 {margin-top: 3rem}
.mt-14 {margin-top: 3.5rem}
.mt-16 {margin-top: 4rem}
.mt-20 {margin-top: 5rem}

.-mb-3 {margin-bottom: -0.75rem}
.-mb-9 {margin-bottom: -2.25rem}
.mb-0 {margin-bottom: 0}
.mb-2 {margin-bottom: 0.5rem}
.mb-4 {margin-bottom: 1rem}
.mb-5 {margin-bottom: 1.25rem}
.mb-6 {margin-bottom: 1.5rem}
.mb-8 {margin-bottom: 2rem}
.mb-10 {margin-bottom: 2.5rem}
.mb-12 {margin-bottom: 3rem}
.mb-14 {margin-bottom: 3.5rem}
.mb-16 {margin-bottom: 4rem}
.mb-20 {margin-bottom: 5rem}
.mb-24 {margin-bottom: 6rem}

.ml-auto {margin-left: auto}
.ml-8 {margin-left: 2rem}

.mr-5 {margin-right: 1.25rem}
.mr-8 {margin-right: 2rem}

.px-0 {padding-left: 0; padding-right: 0}
.px-4 {padding-left: 1rem; padding-right: 1rem}
.px-10 {padding-left: 2.5rem; padding-right: 2.5rem}

.py-5 {padding-top: 1.25rem; padding-bottom: 1.25rem}

.pt-12 {padding-top: 3rem}
.pt-16 {padding-top: 4rem}

.pl-10 {padding-left: 2.5rem}
.pl-16 {padding-left: 4rem}

.pr-16 {padding-right: 4rem}
.pr-20 {padding-right: 5rem}
.pr-24 {padding-right: 6rem}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {margin-top: 1rem}


@media (min-width: 640px) {
  .sm\:mb-0 {margin-bottom: 0}
}

@media (min-width: 768px) {
  .md\:mb-0 {margin-bottom: 0}
  .md\:mb-6 {margin-bottom: 1.5rem}
  .md\:mb-10 {margin-bottom: 2.5rem}
  .md\:mb-14 {margin-bottom: 3.5rem}
  .md\:mb-28 {margin-bottom: 7rem}
  .md\:mb-50 {margin-bottom: 12.5rem}

  .md\:ml-20 {margin-left: 5rem}
  .md\:mr-20 {margin-right: 5rem}

  .md\:px-0 {padding-left: 0; padding-right: 0}
}

@media (min-width: 1024px) {
  .lg\:mb-0 {margin-bottom: 0}
  .lg\:mb-8 {margin-bottom: 2rem}

  .lg\:mr-5 {margin-right: 1.25rem}
  .lg\:mb-20 {margin-bottom: 5rem}

  .lg\:px-0 {padding-left: 0; padding-right: 0}
}

@media (min-width: 1280px) {
  .xl\:mb-0 {margin-bottom: 0}
}

// @media (min-width: 1536px) {
//   .xxl\:mb-0 {margin-bottom: 0}
// }
