.btn {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.75rem;
  padding: 0.5rem 1.5rem;
  border-radius: 100vw;
  font-size: 1rem;
  line-height: 1.17;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  & > svg {
    transition: all 0.15s ease-in-out;
  }

  &--teal {
    background-color: $midnight-teal;
    color: $white;

    &:is(:focus, :hover) {
      opacity: 0.9;

      & > svg {
        color: $midnight-teal;
      }
    }
  }


  // &--change-slide {
  //   position: absolute;
  //   top: 50%;
  //   translate: 0 -50%;
  //   display: grid;
  //   place-content: center;
  //   padding: 0.375rem;
  //   aspect-ratio: 1/1;
  //   border-radius: 100vw;
  //   background-color: $red;
  //   color: $white;
  //   z-index: 2;
  //   cursor: pointer;
  //   transition: all 0.3s ease-in-out;

  //   &:is(:focus, :hover) {
  //     background-color: hsl(0, 100%, 45%, 0.6);
  //   }
  // }

  &--last-slide {
    & .last-item {
      position: absolute;
      inset: 0;
      display: grid;
      place-content: center;

      & > a {
        display: flex;
        align-items: center;
        gap: 0.75rem;
        padding: 0.5rem 0.5rem 0.5rem 1.5rem;
        background-color: $red;
        border-radius: 60px;
        color: $white;
        font-size: 1.25rem;
        line-height: 1.2;
        text-decoration: none;
        transition: all 0.3s ease-in-out;

        & > span {
          padding: 0.375rem;
          background-color: $white;
          border-radius: 100vw;
          color: $red;
        }

        &:is(:focus, :hover) {
          background-color: hsl(0, 100%, 45%, 0.6);
        }
      }
    }
  }
}
