.posts-section {
  padding-bottom: 50px;
}
.posts {
  display: grid;
  grid-template-columns: repeat(var(--_columns, 2), minmax(0, 1fr));
  gap: 4.5rem 1rem;

  @include md-block {
    gap: 3rem 1rem;
    grid-template-columns: repeat(min(var(--_columns, 2), 2), minmax(0, 1fr));
  }

  @include sm-block {
    gap: 2.5rem 1rem;
    grid-template-columns: minmax(0, 1fr);
  }

  .post-preview {
    height: 422px;

    @include lg-block {
      height: 400px;
    }

    @include md-block {
      height: 350px;
    }
  }
}
