.post-preview {
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
  position: relative;

  &:hover {
    .post-preview__content-wrapper {
      background-color: $white;
    }
  }
}
.post-preview__image {
  display: block;
  height: 100%;
  img {
    height: 100%;
    object-fit: cover;
  }
}
.post-preview__content-wrapper {
  @include anim();
  position: absolute;
  left: 16px;
  right: 16px;
  bottom: 16px;
  background-color: rgba($white, 0.85);
  padding: 16px 24px;
  border: 1px solid $black;
  border-radius: 8px;
  display: flex;
}
.post-preview__news-ico {
  margin-right: 8px;
}
.post-preview__content {
  width: 100%;
}
.post-preview__title {
  @include fp(20, 24);
  margin-bottom: 8px;
}
.post-preview__description {
  padding-bottom: 8px;
  position: relative;
  @include fp(16, 20);

  @include md-block {
    @include fp(14, 20);
  }

  &::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 1px;
    background-color: $black;
    bottom: 0;
    left: 0;
  }
}
.post-preview__footer {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
}
.post-preview__date {
  @include fp(16, 20);
}
.post-preview__open {
  @include fp(16, 20);
  color: $red;
  text-decoration: underline;
}
.post-preview__more {
  position: absolute;
  inset: 0;
  display: grid;
  place-content: center;

  & > a {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.5rem 0.5rem 0.5rem 1.5rem;
    background-color: $red;
    border-radius: 60px;
    color: $white;
    font-size: 1.25rem;
    line-height: 1.2;
    text-decoration: none;
    transition: all 0.3s ease-in-out;

    & > span {
      padding: 0.375rem;
      background-color: $white;
      border-radius: 100vw;
      color: $red;
    }

    &:is(:focus, :hover) {
      background-color: hsl(0, 100%, 45%, 0.6);
    }
  }
}
