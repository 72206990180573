.product-price {
  font-family: 'RobotoC', sans-serif;
  @include fp(32, 32);
  display: flex;
  flex-direction: column;

}
.product-price__old-price {
  @include fp(16, 20);
  text-decoration: line-through;
}
