.preview-title {
  margin-top: auto;
  text-transform: initial;
  a {
    display: flex;
    align-items: center;

    & > svg {
      flex-shrink: 0;
    }
  }
  span {
    margin-right: 8px;
    font-family: $font-anton;
    @include fp(32, 32);
    font-weight: 500;
    max-width: 100%;
    overflow-wrap: break-word;

    @container (max-width: 18rem) {
      font-size: 1.25rem;
      line-height: 1.1;
    }
  }

  @include lg-block {
    @include fp(24, 32);
  }
}
