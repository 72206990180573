.pagination-section {
  margin-top: 2.5rem;

  .App_search-page & {
    // margin-top: 0;
    margin-bottom: 6rem;

    @include sm-block {
      margin-top: 2.5rem;
      margin-bottom: 3rem;
    }
  }
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination {
  & a,
  & button,
  & span {
    color: $primary;
    @include fp(48, 48);
    @include anim(.2);
    font-family: 'Anton', sans-serif;

    &:not(:last-child) {
      margin-right: 24px;
    }
  }

  & a,
  & button {
    cursor: pointer;
    background-color: transparent;
    border: none;
  }

  & a:hover,
  & button:hover {
    color: rgba($red, .9);
  }

  & a.active,
  & button.active {
    color: $red;
  }
}
