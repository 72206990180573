.checkout-shipping {
  max-width: 546px;
  width: 100%;
  margin: 0 auto;
}

.checkout-shipping__address-block {
  padding: 32px;
  background-color: $gray-1;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 48px;
}

.checkout-shipping__address-title {
  font-family: 'RobotoC', sans-serif;
  margin-bottom: 16px;
  @include fp(18, 14);
  width: 100%;
}

.checkout-shipping__address {
  font-family: 'RobotoC', sans-serif;
  @include fp(20, 20);
  margin-right: auto;
}

.checkout-shipping__change-address {
  color: $red;
  font-family: 'RobotoC', sans-serif;
  @include fp(16, 20);
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

.checkout-shipping__methods-block {
  display: flex;
  flex-direction: column;
}

.checkout-shipping__methods-title {
  text-transform: lowercase;
  @include fp(20, 20);
  font-family: 'RobotoC', sans-serif;

  &:first-letter {
    text-transform: capitalize
  }
}

.checkout-shipping__methods {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.checkout-shipping__method {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;

  &:hover:not(&.active) {
    .checkout-shipping__method-checkbox {
      &:after {
        background-color: $gray-1;
      }
    }
  }
}

.checkout-shipping__method-checkbox {
  max-width: 16px;
  width: 100%;
  height: 16px;
  border-radius: 100%;
  border: 1px solid $gray-1;
  position: relative;
  margin-right: 12px;

  &:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 12px;
    @include center();
    border-radius: 100%;
    @include anim();
  }

  .active & {
    border: 1px solid $primary;

    &:after {
      background-color: $primary;
    }
  }
}

.checkout-shipping__method-name {
  margin-right: auto;

  &:first-letter{
    text-transform: capitalize
  }
}

.checkout-shipping__method-description {
  margin-top: 0.5rem;
  margin-left: 1.75rem;
  color: $gray-2;
}

.checkout-shipping__method-price {
  @include fp(20, 20);
  font-family: 'RobotoC', sans-serif;
}
.checkout-shipping__method-address {
  padding-left: 8px;
  line-height: 36px;
  height: 36px;
  margin-top: 20px;
}
.checkout-shipping__next {
  margin-top: 50px;
}
.checkout-shipping__back {
  cursor: pointer;
  display: block;
  margin: 20px auto 0;
  font-family: 'RobotoC', sans-serif;
  @include fp(18, 20);
  color: $gray-2;
}
