.footer-col-title {
  font-family: $font-roboto;
  @include fp(20, 24);
  color: rgba($white, 0.5);
  margin-bottom: 30px;

  @include sm-block {
    @include fp(16, 24);
  }
}

@import "./components";

.stickyFooter {
  flex-grow: 1;
}
.footer-section {
  padding: 95px 0 60px;
  color: $white;
  background: $primary;
  font-family: $font-roboto;

  a {
    font-family: $font-roboto;
    color: $white;
  }
}
.footer {
  display: flex;
  flex-direction: column;
}

.footer__wrapper {
  display: flex;
  padding-block-end: 1rem;
  border-bottom: solid 1px #FFF;

  > :not(:last-of-type) {
    margin-right: 41px;
  }

  @include md-block {
    flex-wrap: wrap;
    justify-content: space-between;

    > :not(:last-of-type) {
      margin-right: 0;
    }
  }
}
.footer__global {
  @include md-block {
    order: 1;
  }
}
.footer__write-us {
  @include md-block {
    order: 3;
  }
  @include sm-block {
    order: 4;
  }
}
.footer__site-map {
  @include md-block {
    order: 2;
  }

  @include sm-block {
    order: 3;
  }
}
.footer__info {
  @include md-block {
    order: 4;
  }

  @include sm-block {
    order: 2;
  }
}

.footer__socials {
  margin-top: 1.25rem;
}

.btn--edit-cookies {
  cursor: pointer;
}

.footer__columns {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 1.875rem 1.5rem;

  @include sm-block {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1.25rem;
  }
}

.footer-column {
  &__title {
    margin-bottom: 2.5rem;
    color: rgba($white, 0.5);
    font-family: $font-roboto;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1;

    @include sm-block {
      margin-bottom: 1.25rem;
      font-size: 1.125rem;
    }
  }

  &__list-item-caption {
    margin-bottom: 0.25rem;
    color: rgba($white, 0.5);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.2;

    @include sm-block {
      font-size: 0.875rem;
    }
  }

  &__list {
    --_gap: 1.25rem;

    @include sm-block {
      --_gap: 1rem;
    }

    &--tight {
      --_gap: 1rem;

      @include sm-block {
      --_gap: 0.3125rem;
    }
    }
  }

  &__list-item {
    color: $white;
    font-family: $font-roboto;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.2;

    @include sm-block {
      font-size: 0.875rem;
    }

    & + & {
      margin-top: var(--_gap);
    }
  }
}
