.checkout-summary {
  background-color: #f3f4f5;
  border-bottom: solid 1px $primary;
  border-top: solid 1px $primary;

  &__toggle {
    display: flex;
    align-items: center;

    color: $primary;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.1111;

    & > span {
      margin-left: 1rem;
      margin-right: 0.5rem;
    }

    & > .arrow {
      transition: all 0.3s ease-in-out;
    }

    &[aria-expanded='true'] > .arrow {
      rotate: 180deg;
    }
  }

  &__content {
    max-height: 0;
    transition: all 0.5s ease-in-out;
    overflow: hidden;
    opacity: 0;

    &[data-expanded='true'] {
      max-height: 100rem;
      opacity: 1;
    }
  }
}
