.cart-product-preview {
  position: relative;
  display: flex;

  & + .cart-product-preview {
    padding-top: 2rem;

    @media (min-width: 48rem) {
      padding-top: 2.5rem;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      width: 100%;
      height: 1px;
      background-color: $gray-1;
    }
  }

  &.small {
    &:after {
      display: none;
    }
  }
}

.cart-product-preview__image-wrapper {
  position: relative;
  margin-right: 16px;
  max-width: 128px;
  width: 100%;
  height: 128px;

  .small & {
    max-width: 80px;
    height: 80px;
  }

  @include sm-block {
    max-width: 104px;
    height: 104px;
  }
}

.cart-product-preview__quantity {
  width: 23px;
  height: 23px;
  @include fp(18, 22);
  right: -10px;
  top: -10px;
}

.cart-product-preview__image {
  border: 1px solid $primary;
  border-radius: 8px;
  object-fit: cover;
}

.cart-product-preview__info {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.cart-product-preview__info__header {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;

  & .cart-product-preview__price {
    flex-shrink: 0;
  }
}

.cart-product-preview__title {
  cursor: pointer;
  @include fp(32, 32);


  .small & {
    @include fp(20, 20);
    font-family: 'RobotoC', sans-serif;
  }

  @include sm-block {
    @include fp(24, 24);
  }
}

.cart-product-preview__price {
  @include fp(20, 20);
  font-family: RobotoC, sans-serif;
}

.cart-product-preview__info__footer {
  margin-top: auto;
  display: flex;
  align-items: flex-end;
}

.cart-product-preview__remove {
  margin-left: auto;
  cursor: pointer;
  @include fp(18, 20);
  color: $gray-2;
  @include anim();

  &:hover {
    color: $primary;
  }
}
