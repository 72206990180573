.checkout-information {
  padding: 0 114px;
}
.checkout-information__form {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  max-width: 546px;
  width: 100%;
  margin: 0 auto;
}
.checkout-information__form-wrapper {
  display: flex;
  column-gap: 24px;
}
.checkout-information__company-status-wrapper {
  display: flex;
}
.checkout-information__company-status {
  width: 50%;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
  color: $gray-1;
  user-select: none;

  &.active {
    color: $black;
  }
}