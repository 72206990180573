.banner {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 6.25rem;
  margin-top: 2.625rem;
  margin-bottom: -1.3125rem;
  padding: 1.25rem 3rem 1.5rem 2.5rem;
  background-color: $navy-blue;
  color: white;
  font-size: 1.25rem;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    justify-items: center;
    gap: 2.5rem;
    font-size: 1rem;
  }

  &__cta {
    display: inline-flex;
    padding-right: 4.375rem;
  }

  &__title {
    font-size: 2.25rem;
    line-height: 1.5;
    margin-bottom: 0.375rem;

    @media screen and (max-width: 768px) {
      font-size: 1.75rem;
      line-height: 1.3;
    }
  }
}

.campaign-banner {
  position: absolute;
  top: 0;
  right: 0;
  display: grid;
  justify-items: end;
  width: min(12rem, 100%);
  aspect-ratio: 1/0.875;
  color: white;
  font-size: 0.875rem;
  text-align: right;

  & > * {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
  }

  & > svg {
    width: 100%;
    height: auto;
  }

  & > div {
    padding: 1rem;
    width: 20ch;
  }
}
