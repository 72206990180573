@import './components';

.header__wrapper {
  height: 64px;
}

.header-section {
  top: -64px;
  display: flex;
  align-items: center;
  color: #000;
  height: 64px;
  background-color: white;

  &--sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    transition: top 0.3s ease-in-out;
  }

  &::after {
    position: absolute;
    content: '';
    width: 100%;
    bottom: 0;
    background-color: $primary;
    height: 1px;
  }

  .header {
    display: flex;
    align-items: center;
  }
}

