.socials {
  display: flex;
  align-items: center;

  & > :not(:last-child) {
    margin-right: 24px;
  }

  & a {
    display: flex;
    align-items: center;
    font-size: 2rem;
  }
}
