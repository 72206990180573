.checkout-shopping-cart {
  position: relative;
  z-index: 1;

  @include col();
  @include size(5);
  height: 100vh;

}
.checkout-shopping-cart-wrapper {
  padding: 56px 0 90px;
  height: 100%;
  width: 100%;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  row-gap: 40px;

  @media (max-width: 767px) {
    padding: 2rem 0 1.75rem;
  }

  > :not(:last-child) {
    padding-bottom: 40px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: $gray-1;
    }
  }
}
.checkout-shopping-cart__prices {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}
.checkout-shopping-cart__price-row {
  display: flex;
  justify-content: space-between;
}
.checkout-shopping-cart__price-label {
  font-family: 'RobotoC', sans-serif;
  @include fp(18, 14);
}
.checkout-shopping-cart__price {
  font-family: 'RobotoC', sans-serif;
  @include fp(20, 20);
}
.checkout-shopping-cart__total-price-row {
  display: flex;
  justify-content: space-between;
}
.checkout-shopping-cart__total-price-label {
  font-family: 'RobotoC', sans-serif;
  @include fp(18, 14);
  text-transform: uppercase;

  @include sm-block {
    font-size: 1rem;
    line-height: 1;
  }
}
.checkout-shopping-cart__total-price {
  font-size: 2rem;
  line-height: 1;
  white-space: nowrap;

  @include sm-block {
    font-size: 1.25rem;
  }
}
