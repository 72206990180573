* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // outline: 1px solid red;
}

*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  font-family: $font-roboto;
  color: $primary;
  @include fp(16, 24);

  &.scroll-lock {
    overflow: hidden;
  }
}

.root,
.App {
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

// Why has img tag border radius? It applies to all images
// and not every image needs a border radius.
img:not(.call-to-action__image) {
  border-radius: 8px;
  overflow: hidden;
}

.container {
  width: 100%;
  max-width: 900px;
  margin-inline: auto;
}

ul, li, p, a, h1, h2, h3, h4, h5, body, address, label {
  margin: 0;
  padding: 0;
}

li {
  display: block;
}

input, input:hover, input:focus, input:active, button, button:hover, button:focus, button:active, textarea,
textarea:hover, textarea:focus, textarea:active, select, select:hover, select:focus, select:active, option,
option:hover, option:focus, option:active, div {
  outline: none;
}

a {
  display: inline-block;
}

a,
a:hover,
a:active,
a:focus {
  text-decoration: none;
  outline: none;
}

input {
  border-radius: 0;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-anton;
  text-transform: uppercase;
  font-weight: 100;
}

h1 {
  @include h1();
}

h2 {
  @include h1();
}

a {
  color: $primary;

  @include fp(16, 20);

  .red {
    color: $red;
    position: relative;

    &::after {
      @include anim;
      position: absolute;
      content: "";
      width: 100%;
      height: 1px;
      background-color: $red;
      bottom: 1px;
      left: 0;
    }

    &:hover {
      &::after {
        width: 0;
      }
    }
  }
}

.loader {
  position: fixed;
  inset: 0;
  display: grid;
  place-content: center;
  background-color: hsl(0, 0%, 100%, 0.8);
  color: $primary;
  font-size: 1.25rem;
  font-weight: 600;
  z-index: 100000;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: $primary;
  border-radius: 0.5rem;
}

::-webkit-scrollbar-thumb:hover {
  background: $black;
}

// * {
//   outline: solid 1px red;
// }

.LazyLoad {
  width: inherit;
  height: inherit;
  aspect-ratio: inherit;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
