.categories {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(100%, 16.5rem), 1fr));
  gap: 2rem 1rem;
}

.categories__point {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.categories__point-image {
  // width: min(100%, 16.5rem);
  object-fit: cover;
  height: 186px;
  width: auto;
  aspect-ratio: 1.4;
  border-radius: 8px;
}

.categories__point-link {
  svg {
    margin: 0 0 6px 15px;
  }
}

.categories__point-title {
  @include fp(28, 38);
  display: inline;
}

.categories__point-sub {
  display: flex;
  flex-direction: column;
  row-gap: 12px;

  li, a {
    @include fp(20, 24);
    font-family: $font-roboto;
    text-decoration: underline;
    text-transform: none;
    font-weight: 400;
    cursor: pointer;

    &:first-letter {
      text-transform: capitalize;
    }
  }
}
