@import './components';

.checkout-data-section {
  height: 100vh;
  box-sizing: border-box;
  // background: white;
  // background: linear-gradient(
  //   90deg,
  //   white 50%,
  //   rgba($primary, .05) 50%,
  //   rgba($primary, .05) 100%
  // );
  overflow-x: hidden;

  .container {
    height: 100%;
  }
}

@media (min-width: 48rem) {
  .checkout-data {
    @include row-flex();
    position: relative;

    &:after {
      content: '';
      position: absolute;
      width: 50vw;
      height: 100%;
      top: 0;
      left: 56%;
      background-color: rgba($primary, .05);
    }
  }
}
