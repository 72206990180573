.contact__sticky-button {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  place-items: center;
  width: 9.375rem;
  aspect-ratio: 1;
  background-color: $navy-blue;
  border-radius: 100vw;
  color: $white;
  font-size: 0.65rem;
  letter-spacing: 2px;
  z-index: 10;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  // translate: 0 50%;
  // opacity: 0;
  // animation: stickyButtonAppear 1s ease-in-out forwards 1s;

  & > * {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
  }

  // &-icon {
  //   max-width: 4.5rem;
  //   max-height: 4.5rem;
  // }

  & > svg,
  & img {
    width: 100%;
    height: 100%;
  }

  & img {
    max-width: 4.5rem;
    max-height: 4.5rem;
    object-fit: contain;
  }

  &:is(:focus, :hover) {
    box-shadow: 0 0 0.05rem 0.125rem $red;
    rotate: 5deg;
  }

  @include sm-block {
    bottom: 1rem;
    right: 1rem;
    width: 4rem;

    & img {
      width: 1.5rem;
    }
  }
}

@keyframes stickyButtonAppear {
  0% {
    translate: 0 50%;
    // rotate: 0;
    // scale: 0;
    opacity: 0;
  }
  50% {
    // rotate: 360deg;
    // opacity: 0;
  }
  70% {
    translate: 0 -2.5%;
    opacity: 1;
  }
  100% {
    translate: 0 0;
    // rotate: 0;
    // scale: 1;
    opacity: 1;
  }
}
