.preview-price-old {
  margin-right: 8px;
  @include fp(14, 20);
  color: rgba($primary, 0.5);
  text-decoration: line-through;
}
.preview-price-current {
  @include fp(20, 20);
  font-family: 'RobotoC', sans-serif;
}
.preview-price-onsale {
  color: $red;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 0.8333;
}
