.menu {
  @include md-block {
    display: none;
  }
  margin-left: 20px;

  &__list {
    display: flex;
  }

  &__item {
    position: relative;
    z-index: 1;

    &:nth-child(5)::after {
      content: '';
      position: absolute;
      top: 1rem;
      right: 0.5rem;
      display: block;
      width: 0.5rem;
      height: 0.5rem;
      aspect-ratio: 1;
      background-color: #e70000;
      border-radius: 100vw;
    }

    &:is(:focus-within, :hover) .submenu {
      display: block;
    }
  }

  &__link {
    display: inline-block;
    padding: 24px 20px;
    color: $primary;
    @include fp(18, 16);
    @include anim;

    &:hover,
    &.active {
      color: $white;
      background-color: $primary;
    }
  }
}

.submenu {
  display: none;
  position: absolute;
  min-width: 100%;
  background-color: white;
  box-shadow: 2px 2px 8px 4px hsla(0 0% 0% / 0.05);
  z-index: 1;

  & a {
    display: block;
  }
}
