.business-associate-section {
  padding: 40px 0;
  overflow-x: hidden;
}
.business-associate {
  position: relative;
  padding-bottom: 98px;

  &::after {
    position: absolute;
    content: "";
    width: 100%;
    height: 1px;
    background-color: $primary;
    bottom: 0;
    left: 0;
  }
  &__title {
    @include fp(48, 48);
    margin-bottom: 40px;
  }
  &__partners {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    gap: 80px 70px;

    @media (min-width: 768px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media (min-width: 1024px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }
  &__partner {
    position: relative;
    z-index: 1;
    padding-left: 16px;
    @include fp(20, 20);
    font-family: $font-roboto;
    line-height: 1.2;
  }
  &__name {
    margin-bottom: 0.75rem;
    color: $midnight-blue;
    font-family: $font-roboto;
    font-size: 1.25rem;
    font-weight: 700;
    text-transform: uppercase;
  }
  &__contact {
    display: flex;
    align-items: center;
    gap: 1rem;
    white-space: normal;

    & > svg {
      color: $red;
    }

    & + & {
      margin-top: 0.825rem;
    }
  }
  &__contact-label {
    margin-right: 5px;
    background-color: $white;
  }
  &__contact-link {
    font-family: $font-roboto;
    font-size: 1.25rem;
    font-weight: 300;
  }

  &__road {
    position: absolute;
    top: 0;
    right: calc(-100% + 533px);

    @include md-block {
      left: 0;
      right: auto;
    }
    @include sm-block {
      top: 50px;
      left: -160px;
    }
  }
  &__legal-data {
    word-wrap: break-word;
  }
}
