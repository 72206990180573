.image-list {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr) auto;
  gap: 1rem;

  @media (min-width: 48rem) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  &__item {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: subgrid;
    grid-row: span 2;
    padding: 0.75rem 0.25rem;
    background-color: $navy-blue;
    border-radius: 0.5rem;
    color: $white;

    & li {
      text-align: center;
    }

    & img {
      width: 100%;
      height: auto;
      margin-block: auto;
      object-fit: auto;
    }

    & .LazyLoad {
      margin-block: auto;
    }

    & a {
      color: $white;
    }
  }
}

.collapsable-article {
  &__content {
    overflow: hidden;
    margin-bottom: 1rem;
    transition: max-height 0.3s ease-in-out;
  }

  &__toggle {
    display: inline-flex;
    align-items: center;
    gap: 1rem;
    cursor: pointer;

    & svg {
      width: 1rem;
      height: auto;
      transform: rotateZ(90deg);
      transition: transform 0.3s ease-in-out;
    }

    &[aria-expanded=true] svg {
      transform: rotateZ(270deg);
    }

    &:is(:focus, :hover) {
      text-decoration: underline;
    }
  }
}
