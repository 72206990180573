.wuswug {
  color: rgba($primary, 0.8);

  & h2,
  & h3,
  & h4 {
    margin-bottom: 1.5rem;
    font-weight: 500;
    line-height: 1.2;
  }

  & h2 {
    margin-top: 2.5rem;
    font-size: 1.25rem;
  }

  & h3 {
    font-size: 1.125rem;
  }

  & h4 {
    font-size: 1rem;
  }

  & p {
    line-height: 1.5;
  }

  & p, & ul, & ol {
    margin-bottom: 0.5rem;
  }


  // & > * {
  //   margin-bottom: 27px;
  // }
  // & > *:last-child {
  //   margin-bottom: 0;
  // }
  // & > *:first-child {
  //   margin-top: 0;
  // }
  // p {
  // }
  // h4 {
  //   margin: 40px 0 27px;
  //   color: $primary;
  // }

  & ul {
    list-style-position: inside;
    margin-right: 10px;
  }

  & li {
    display: list-item;
    position: relative;

    &::marker {
      position: absolute;
      left: 0;
    }

    & + li {
      margin-top: 0.25rem;
    }
  }
}
