.swiper-slide {
  &:not(&-visible):not(&-active) {
    opacity: 0.05;
    filter: blur(2px);
  }
}

.slide--read-more {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  place-content: center;
  height: 100%;
  overflow: hidden;
  border-radius: 8px;

  & > * {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
  }

  & .slide__content {
    display: grid;
    place-content: center;
  }

  & .slide__button {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.5rem 0.5rem 0.5rem 1.5rem;
    background-color: $red;
    border-radius: 60px;
    color: $white;
    font-size: 1.25rem;
    line-height: 1.2;
    text-decoration: none;
    transition: all 0.3s ease-in-out;

    & > span {
      padding: 0.375rem;
      background-color: $white;
      border-radius: 100vw;
      color: $red;
    }
  }

  &:is(:focus, :hover) .slide__button {
    background-color: hsl(0, 100%, 45%, 0.6);
  }

  .type-previews & {
    height: 616px;
    width: min(30.875rem, 85vw);

    @include md-block {
      height: 292px;
    }
  }
}

.App .swiper-button-next,
.App .swiper-button-prev {
  position: absolute;
  width: 2rem;
  height: 2rem;
  aspect-ratio: 1/1;
  margin-top: -1rem;
  padding: 0.375rem;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--swiper-navigation-color, var(--swiper-theme-color));
  border-radius: 100vw;
  background-color: $red;

  &::after {
    font-size: 1rem;
  }

  &:is(:focus, :hover) {
    background-color: hsl(0, 100%, 45%, 0.6);
  }
}

@media (min-width: 1101px) {
  .App .swiper-button-prev {
    left: -4rem;
  }
}

.swiper-button-next::after {
  content: url("data:image/svg+xml,%3Csvg width='23' height='15' viewBox='0 0 23 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 7.5H22M22 7.5L14.9787 1M22 7.5L14.9787 14' stroke='white' strokeLinejoin='bevel' /%3E%3C/svg%3E") !important;
}

.swiper-button-prev::after {
  content: url("data:image/svg+xml,%3Csvg width='23' height='15' viewBox='0 0 23 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M23 7.5L0.999999 7.5M0.999999 7.5L8.02128 14M0.999999 7.5L8.02128 1' stroke='white' strokeLinejoin='bevel' /%3E%3C/svg%3E") !important;
}

.slider-pagination {
  display: flex;
  gap: 0.5rem;
  margin-top: 3rem;

  &__bullet {
    display: block;
    width: 3rem;
    height: 0.5rem;
    background-color: hsl(0 0% 0% / 0.1);
    border-radius: 100vw;
    transition: all 0.3s ease-in-out;

    &--white {
      background-color: hsl(0 0% 100% / 0.1);
    }

    &.is-active {
      background-color: $red;
    }
  }
}
