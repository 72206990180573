.close-button {
    position: absolute;
    margin: auto;
    width: 22px;
    cursor: pointer;
}
.close-button__inner {
    width: inherit;
    text-align: center;

    display: flex;
    justify-content: center;
}
.close-button__label {
    font-size: 12px;
    line-height: 23px;
    text-transform: uppercase;
    color: $red;
    transition: all 0.3s ease-in;
    opacity: 0;
    cursor: pointer;
}
.close-button__inner:before,
.close-button__inner:after {
    position: absolute;
    content: '';
    height: 2px;
    width: inherit;
    background: $primary;
    left: 0;
    transition: all 0.3s ease-in;
}

.close-button__inner:before {
  top: calc(50% - 1px);
    transform: rotate(45deg);
}

.close-button__inner:after {
    bottom: calc(50% - 1px);
    transform: rotate(-45deg);
}

.close-button:hover .close-button__label {
    opacity: 1;
}

.close-button:hover .close-button__inner:before,
.close-button:hover .close-button__inner:after {
    transform: rotate(0);
}

.close-button:hover .close-button__inner:before {
    top: 0;
}

.close-button:hover .close-button__inner:after {
    bottom: 0;
}
