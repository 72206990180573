.container {
  @include container();
}

.container-big,
.debugGrid-big > div {
  max-width: 1304px;
}

.container,
.container-big {
  @include anim();
  @include lg(max-width, $break_md - $fields_lg * 2);
  @include md(max-width, 100%);
  @include sm(max-width, 100%);
  @include xs(max-width, 100%);
}

.container-fluid {
  @include container-full();
}
