.bg-red {background-color: #E70000 !important}
.bg-navy-blue {background-color: $navy-blue}

.bg-center {
  background-position: center;
}

.bg-cover {
  background-size: cover;
}
.bg-contain {
  background-size: contain;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}
