.global-info {
  max-width: 426px;
  width: 100%;

  @include md-block {
    max-width: 405px;
  }
}
.global-info__list {
  margin-bottom: 1.875rem;
}
.global-info__logo-329x68 {
  max-width: 100%;
  margin-bottom: 17px;
}
.global-info__label,
.global-info__content {
  font-size: 1rem;
  line-height: 2;

  @include sm-block {
    font-size: 0.875rem;
    line-height: 1;
  }
}
