.button {
    @include button();
    border: none;
    position: relative;
    background-color: $red;
    min-width: 50px;
    // height: 50px;
    width: 100%;
    text-align: left;
    cursor: pointer;
    color: $white;
    padding: 15px 24px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    box-sizing: border-box;

    span {
        display: block;
        width: 100%;
    }

    &:hover {
        opacity: .6;
    }

    &[disabled] {
        cursor: not-allowed;
        opacity: 0.6;
        pointer-events: none;
    }

    svg {
        position: absolute;
        @include center-vert();
        right: 8px;
    }

    &.white {
        height: 40px;
        border: 1px solid $black;
        background-color: $white;
        width: fit-content;
        color: $black;
        text-transform: uppercase;
        font-family: 'Anton', sans-serif;
        @include fp(20, 24);
        padding: 0 54px 0 25px;

        @include md-block{
            @include fp(16, 24);
        };
    }

    &.transparent {
        background-color: transparent;
        border: 1px solid $white;
    }
}
