.relative {position: relative}
.fixed {position: fixed}

.hidden {display: none}

.overflow-hidden {overflow: hidden}
.overflow-x-hidden {overflow-x: hidden}

.inset-0 {inset: 0}

:where(.hidden) {display: hidden}
:where(.flex) {display: flex}
:where(.inline-flex) {display: inline-flex}
:where(.grid) {display: grid}
:where(.inline-grid) {display: inline-grid}
.block {display: block !important}
:where(.inline) {display: block}

.justify-center {justify-content: center}
.justify-between {justify-content: space-between}
.justify-end {justify-content: end}
.justify-items-end {justify-items: end}
.items-center {align-items: center}

.w-auto {width: auto}
.w-full {width: 100%}

.max-w-max {max-width: max-content}
.max-w-\[28rem\] {max-width: 28rem}

.h-auto {height: auto}
.h-full {height: 100%}

.min-h-screen {min-height: 100dvh}

@for $i from 1 through 32 {
  .gap-#{$i} {gap: #{$i * 0.25}rem}
}

.-z-1 {z-index: -1}

.col-span-2 {grid-column: span 2 / span 2}
.row-span-2 {grid-row: span 2 / span 2}
.row-span-6 {grid-row: span 6 / span 6}

.flex-shrink-1 {flex-shrink: 1}

.order-1 {order: 1}
.order-2 {order: 2}
.order-3 {order: 3}
.order-4 {order: 4}
.order-5 {order: 5}


.grid-cols-1 {grid-template-columns: minmax(0, 1fr)}
.grid-cols-2 {grid-template-columns: repeat(2, minmax(0, 1fr))}
.grid-cols-3 {grid-template-columns: repeat(3, minmax(0, 1fr))}
.grid-cols-4 {grid-template-columns: repeat(4, minmax(0, 1fr))}
.grid-cols-5 {grid-template-columns: repeat(5, minmax(0, 1fr))}
.grid-cols-6 {grid-template-columns: repeat(6, minmax(0, 1fr))}

@media (min-width: 640px) {
  .sm\:grid-cols-1 {grid-template-columns: minmax(0, 1fr)}
  .sm\:grid-cols-2 {grid-template-columns: repeat(2, minmax(0, 1fr))}
  .sm\:grid-cols-3 {grid-template-columns: repeat(3, minmax(0, 1fr))}
  .sm\:grid-cols-4 {grid-template-columns: repeat(4, minmax(0, 1fr))}
  .sm\:grid-cols-5 {grid-template-columns: repeat(5, minmax(0, 1fr))}
  .sm\:grid-cols-6 {grid-template-columns: repeat(6, minmax(0, 1fr))}
}

@media (min-width: 768px) {
  .md\:grid {display: grid}
  .md\:overflow-visible {overflow: visible}

  .md\:grid-cols-1 {grid-template-columns: minmax(0, 1fr)}
  .md\:grid-cols-2 {grid-template-columns: repeat(2, minmax(0, 1fr))}
  .md\:grid-cols-3 {grid-template-columns: repeat(3, minmax(0, 1fr))}
  .md\:grid-cols-4 {grid-template-columns: repeat(4, minmax(0, 1fr))}
  .md\:grid-cols-5 {grid-template-columns: repeat(5, minmax(0, 1fr))}
  .md\:grid-cols-6 {grid-template-columns: repeat(6, minmax(0, 1fr))}

  .md\:row-span-2 {grid-row: span 2 / span 2}

  @for $i from 1 through 32 {
    .md\:gap-#{$i} {gap: #{$i * 0.25}rem}
  }

  .md\:row-span-2 {grid-row: span 2 / span 2}

  .md\:order-1 {order: 1}
  .md\:order-2 {order: 2}
  .md\:order-3 {order: 3}
  .md\:order-4 {order: 4}
  .md\:order-5 {order: 5}
}

@media (min-width: 1024px) {
  .lg\:block {display: block}
  .lg\:grid {display: grid}
  .lg\:grid-cols-1 {grid-template-columns: minmax(0, 1fr)}
  .lg\:grid-cols-2 {grid-template-columns: repeat(2, minmax(0, 1fr))}
  .lg\:grid-cols-3 {grid-template-columns: repeat(3, minmax(0, 1fr))}
  .lg\:grid-cols-4 {grid-template-columns: repeat(4, minmax(0, 1fr))}
  .lg\:grid-cols-5 {grid-template-columns: repeat(5, minmax(0, 1fr))}
  .lg\:grid-cols-6 {grid-template-columns: repeat(6, minmax(0, 1fr))}

  .lg\:gap-17 {gap: 4.25rem}
}

@media (min-width: 1280px) {
  .xl\:grid-cols-1 {grid-template-columns: minmax(0, 1fr)}
  .xl\:grid-cols-2 {grid-template-columns: repeat(2, minmax(0, 1fr))}
  .xl\:grid-cols-3 {grid-template-columns: repeat(3, minmax(0, 1fr))}
  .xl\:grid-cols-4 {grid-template-columns: repeat(4, minmax(0, 1fr))}
  .xl\:grid-cols-5 {grid-template-columns: repeat(5, minmax(0, 1fr))}
  .xl\:grid-cols-6 {grid-template-columns: repeat(6, minmax(0, 1fr))}
}

// @media (min-width: 1536px) {
//   .xxl\:grid-cols-1 {grid-template-columns: minmax(0, 1fr)}
//   .xxl\:grid-cols-2 {grid-template-columns: repeat(2, minmax(0, 1fr))}
// }


