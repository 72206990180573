.rent-page-info-slider {
    border-radius: 8px;
    overflow: hidden;
}


.rent-page-info-slider-navigation {
    position: absolute;
    cursor: pointer;
    z-index: 1;
    height: 32px;
    bottom: 36px;

    @include sm-block{
        bottom: 0;
    };
}
.rent-page-info-slider-prev-arrow {
    right: 56px;
}
.rent-page-info-slider-next-arrow {
    right: 0;
    transform: rotate(180deg);
}
.rent-page-info-slider__logo{
    position: absolute;
    bottom: 0;
    left: -96px;
    z-index: 50;

    @include sm-block{
        display: none;
    };
}
