@import './components';

.type-previews-section {
    padding: 82px 0 0;
    overflow: hidden;
    position: relative;

    &::after {
        position: absolute;
        content: '';
        width: 100%;
        height: 198px;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: $primary;
        z-index: -1;
    }

    @include md-block{
        padding-top: 43px;
    };

    @include sm-block{
        padding: 48px 0 0;
    };
}
.type-previews {
    padding-bottom: 64px;
    position: relative;

    &::after {
        position: absolute;
        content: '';
        width: 100%;
        height: 1px;
        background-color: $white;
        bottom: 0;
        left: 0;
    }

    @include sm-block{
        padding-bottom: 48px;
    };

    & .swiper {
      overflow: visible;
    }

    & .swiper-slide {
      flex-shrink: 1;
    }
}
.type-previews__header {
    display: flex;
    align-items: center;
    margin-bottom: 40px;

    @include md-block{
        margin-bottom: 16px;
    };

    @include sm-block{
        margin-bottom: 24px;
    };
}
.type-previews__title {
    font-family: 'Anton';
    @include fp(120, 128);
    max-width: 661px;
    width: 100%;
    flex-shrink: 0;

    @include md-block{
        @include fp(48, 48);
        max-width: 273px;
    };

    @include sm-block{
        @include fp(40, 40);
        max-width: none;
    };
}
.type-previews__image-wrapper {
    width: 100%;
    max-width: 563px;
    // height: 468px;
    margin-left: auto;
    z-index: -1;

    // @include md-block{
    //     width: 368px;
    //     height: 197px;
    // };
    @include sm-block{
        display: none;
    };
}
.type-previews__image {
    max-width: 954px;
    overflow: visible;
    object-position: left;
    height: 100%;
    width: auto;
    object-fit: unset;
}
.type-previews__list {
    @include row-flex();
}
