.dropdown {
    position: relative;

    &__button,
    &__content {
        border: solid 1px #0E1F37;
        border-radius: 8px;
    }

    &__button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 0.5rem;
        padding: 0.5rem 1rem;
        background-color: white;
        font-family: $font-roboto;
        font-size: 1.125rem;
        line-height: 0.89;
        white-space: nowrap;
        cursor: pointer;

        & svg {
            transition: all 0.2s ease-in-out;
        }

        &[aria-expanded="true"] svg {
            rotate: 180deg;
        }
    }

    &__content {
        position: absolute;
        top: calc(100% + 0.5rem);
        left: 0;
        min-width: 100%;
        padding: 0.5rem 0;
        background-color: white;
        transition: all 0.2s ease-in-out;
        z-index: 10;
    }

    &__item {
        padding: 0.25rem 1rem;
        transition: all 0.2s ease-in-out;
        white-space: nowrap;
        cursor: pointer;

        &:hover {
            background-color: #0E1F37;
            color: white;
        }

        // & + & {
        //     border-top: solid 1px #EBEBEB;
        // }
    }
}

.range-slider {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  width: 7.5rem;
  height: 1px;
  background: #0E1F37;
  accent-color: #0E1F37;

  &::-webkit-slider-runnable-track,
  &::-moz-range-track {
    background: #0E1F37;
    height: 1px;
  }

  &::-webkit-slider-thumb,
  &::-moz-range-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 0.5rem;
    height: 0.5rem;
    margin-top: -0.25rem;
    background: #0E1F37;
    border: 0;
    border-radius: 50%;
    cursor: pointer;
  }

  @include md-block {
    display: none;
  }
}

.variation-control {
  display: flex;
  flex-wrap: wrap;
  gap: 0.25rem;

  & button {
    padding: 0.5rem 1rem;
    border: solid 1px $primary;
    border-radius: 8px;
    background-color: $white;
    font-family: $font-roboto;
    font-size: 1.125rem;
    line-height: 0.89;
    cursor: pointer;

    &:hover {
      background-color: $primary;
      color: $white;
    }

    &.active {
      background-color: $primary;
      color: $white;

      &.unavailable {
        background-color: $red;
      }
    }

    &:disabled {
      background-color: $gray-1;
      color: $white;
      cursor: not-allowed;
    }
  }

  &__label {
    display: block;
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.25;
  }
}
